import React, { Component } from "react";
import {
  DetailItemKProps,
  DetailItemKState,
} from "../Interface/loginInterface";
import _ from "lodash";
import {
  getCart,
  getComboDetailList,
  saveCart,
} from "../../Redux/Actions/index";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./productModal.css";
import "react-responsive-modal/styles.css";
// import { Modal } from "react-responsive-modal";
import { MdOutlineShoppingCart } from "react-icons/md";
import SuggestedItem from "../SuggestedItems";
import { connect } from "react-redux";
import DoneIcon from "@mui/icons-material/Done";

import { BASE_URL, priceunit } from "../../ApiManager/client-config";
import { Helmet } from "react-helmet";
import ConfirmLocationModal from "../ConfirmLocationModal";
import Swal from "sweetalert2";
import { Modal, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

toast.configure();
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 0,
  px: 0,
  pb: 0,
};

class ProductModal extends Component<DetailItemKProps, DetailItemKState> {
  constructor(props: any) {
    super(props);
    this.state = {
      selectedsize: {},
      size: [],
      itemPrice: "",
      netPrice: "",
      initialPrice: "",
      modifiers: {},
      images: [],
      prevModPrice: 0,
      locationModal: false,
      prevMod: "",
      prevIndex: 0,
      prevKey: "",
      comboDetails: {},
      comboQuantityReached: true,
      itemQuantity: 1,
      make_combos: [],
      prevMakeComboPrice: 0,
      selectedMakeACombos: null,
    };
    this.renderItemPrice = this.renderItemPrice.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  componentWillUnmount() {
    this.state.make_combos.map((options: any) => {
      options.quantity = 0;
      options.option.selected = false;
    });
    this.setState({
      itemQuantity: 1,
      modifiers: {},
      make_combos: [],
      itemPrice: this.state.initialPrice,
      prevMakeComboPrice: 0,
    });
  }
  renderItemPrice(e: any) {
    let item = JSON.parse(e.target.value);
    if (item.discount_price && item.order_channel == "online") {
      this.setState({
        selectedsize: item,
        itemPrice: item.discount_price,
        initialPrice: item.discount_price,
        netPrice: item.mrp,
      });
    } else {
      this.setState({
        selectedsize: item,
        itemPrice: item.mrp,
        initialPrice: item.mrp,
        netPrice: item.mrp,
      });
    }
    if (!_.isEmpty(this.state.modifiers)) {
      Object.keys(this.state.modifiers).map((key) => {
        this.state.modifiers[key].map((item: any, index: any) => {
          item.selected = false;
          this.setState({
            modifiers: this.state.modifiers,
          });
        });
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    // window.scrollTo(0, 0);
    let images: any = [];
    if (!_.isEmpty(nextProps.itemData)) {
      let sizearray = JSON.parse(nextProps.itemData.item_size);
      nextProps.itemData.images.push({
        image_url: `${sizearray[0].image_url}`,
      });
      images = nextProps.itemData.images;
      this.setState({
        images: images,
        size: sizearray,
        selectedsize: sizearray[0],
        itemPrice: nextProps.itemData.price,
        netPrice: nextProps.itemData.price,
        // initialPrice: (sizearray[0].discount_price && (sizearray[0].order_channel == 'online')) ? Math.round(sizearray[0].discount_price) : Math.round(sizearray[0].mrp),
        initialPrice: nextProps.itemData.price,
        modifiers: nextProps.itemData.modifiers,
      });
    }
    if (!_.isEmpty(nextProps.comboData)) {
      nextProps.comboData.images.forEach((element: any) => {
        images.push({
          images_url: `${BASE_URL}${element.image_url}`,
        });
      });
      this.setState({
        images: images,
        itemPrice:
          nextProps.comboData.discount_price &&
          nextProps.comboData.order_channel == "online"
            ? Math.round(nextProps.comboData.discount_price)
            : Math.round(nextProps.comboData.combo_mrp_price),
        netPrice:
          nextProps.comboData.discount_price &&
          nextProps.comboData.order_channel == "online"
            ? Math.round(nextProps.comboData.discount_price)
            : Math.round(nextProps.comboData.combo_mrp_price),
        modifiers: nextProps.comboData.modifiers,
        comboDetails: nextProps.comboData.choices,
      });
      console.log(this.state.comboDetails);
    }
  }
  handleCheck = (key: any, modName: any) => {
    console.log(this.state, "statee");
    let { modifiers, itemPrice } = this.state;

    modifiers[key].map((item: any, index: any) => {
      // console.log(item,modName)
      if (item.modifier_name == modName) {
        if (item.selected == false) {
          item.selected = !item.selected;
          this.setState({
            modifiers: modifiers,
            itemPrice:
              parseInt(itemPrice) + item.modifier_sale_price * item.quantity,
          });
        } else {
          item.selected = !item.selected;
          this.setState({
            modifiers: modifiers,
            itemPrice:
              parseInt(itemPrice) - item.modifier_sale_price * item.quantity,
          });
        }
      }
    });
  };

  addVariation = (key: any, variation: any, itemsize: any) => {
    let { itemPrice, prevMod, prevModPrice, prevKey, comboDetails } =
      this.state;
    let { comboData } = this.props;
    let prevPrice = 0;
    comboDetails[key].map((element: any) => {
      if (element.selected == true) {
        prevPrice = element.extra_price;
      }
      element.selected = false;
    });

    comboDetails[key].map((item: any, index: any) => {
      if (
        variation == item.item_name &&
        itemsize.size == JSON.parse(item.size).size
      ) {
        if (prevMod == "") {
          this.setState({
            comboDetails: comboDetails,
            itemPrice: parseInt(itemPrice) + item.extra_price,
            prevMod: variation,
            prevKey: key,
          });
          item.selected = true;
        } else {
          if (prevKey == key) {
            item.selected = true;
            this.setState({
              comboDetails: comboDetails,
              itemPrice: parseInt(itemPrice) - prevPrice + item.extra_price,
              prevMod: variation,
              prevKey: key,
            });
          } else {
            item.selected = true;
            this.setState({
              comboDetails: comboDetails,
              itemPrice: parseInt(itemPrice) - prevPrice + item.extra_price,
              prevMod: variation,
              prevKey: key,
            });
            console.log(itemPrice);
          }
        }
      }
    });
  };
  closeIcon = () => {
    return (
      <span style={{ position: "fixed", height: "40px", width: "40px" }}>
        {" "}
        <img
          src={process.env.PUBLIC_URL + "/assets/images/close.svg"}
          style={{ width: "100%" }}
        />
      </span>
    );
  };

  handleAdd = async (item: any) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-right",
      iconColor: "white",
      customClass: {
        popup: "colored-toast",
      },
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
    });
    if (sessionStorage.getItem("orderType") == null) {
      this.props.onClose();
      this.setState({ locationModal: true });
      if (!_.isEmpty(item)) {
        let cart: any = [];
        this.props.getCart();
        let data: any = this.props.cart;
        if (!_.isEmpty(data)) {
          cart = JSON.parse(data);
        }
        if (item.is_active == 1) {
          if (item.menu_item_id) {
            let sizearray: any = JSON.parse(item.item_size);
            if (!_.isEmpty(cart)) {
              let found: any = "";
              let modifier_comparison = true;

              cart.forEach((element: any) => {
                if (element["item"]) {
                  if (element["item"].menu_item_id == item.menu_item_id) {
                    if (
                      this.state.selectedsize.size == element.selectedsize.size
                    ) {
                      let cartmodifiers = element["item"].modifiers;
                      let cartMakeCombos = element["item"].make_combo_options;

                      if (cartmodifiers !== undefined) {
                        Object.keys(cartmodifiers).map((key) => {
                          cartmodifiers[key].map((obj: any, index: any) => {
                            Object.keys(this.state.modifiers).map((key) => {
                              this.state.modifiers[key].map(
                                (item: any, index: any) => {
                                  if (obj.modifier_name == item.modifier_name) {
                                    if (obj.selected == item.selected) {
                                      return;
                                    } else {
                                      return (modifier_comparison = false);
                                    }
                                  }
                                }
                              );
                            });
                          });
                        });
                        if (modifier_comparison) {
                          Object.keys(cartmodifiers).map((key) => {
                            cartmodifiers[key].map((obj: any, index: any) => {
                              Object.keys(this.state.modifiers).map((key) => {
                                this.state.modifiers[key].map(
                                  (item: any, index: any) => {
                                    if (
                                      obj.modifier_name == item.modifier_name
                                    ) {
                                      if (obj.selected == item.selected) {
                                        obj.quantity += item.quantity;
                                      }
                                    }
                                  }
                                );
                              });
                            });
                          });
                          element.quantity =
                            element.quantity + this.state.itemQuantity;
                          element.totalItemPrice =
                            this.state.itemPrice * this.state.itemQuantity +
                            element.totalItemPrice;
                          return (found = 1);
                        }
                      } else {
                      }
                    }
                  }
                }
              });
              console.log("found", found);
              if (found == "") {
                const itemcart = {
                  item: item,
                  quantity: this.state.itemQuantity,
                  selectedsize: this.state.selectedsize,
                  price: Math.round(this.state.initialPrice),
                  totalItemPrice:
                    Math.round(this.state.itemPrice) * this.state.itemQuantity,
                  image: item.images.length > 0 ? item.images[0].image_url : "",
                };
                cart.push(itemcart);
              }
            } else {
              const itemcart = {
                item: item,
                quantity: this.state.itemQuantity,
                selectedsize: this.state.selectedsize,
                price: Math.round(this.state.initialPrice),
                totalItemPrice:
                  Math.round(this.state.itemPrice) * this.state.itemQuantity,
                image: item.images.length > 0 ? item.images[0].image_url : "",
              };

              cart.push(itemcart);
            }
          }
          console.log(cart, "cart=483");
          this.props.saveCart(cart);
          this.props.getCart();
          if (!_.isEmpty(this.state.modifiers)) {
            Object.keys(this.state.modifiers).map((key: any) => {
              {
                this.state.modifiers[key].map((element: any) => {
                  element.selected = false;
                });
              }
            });
          }
          this.setState({
            prevModPrice: 0,
            prevIndex: 0,
            prevMod: "",
            itemQuantity: 1,
          });
          // await Toast.fire({
          //   icon: "success",
          //   title: `${item.item_name} Added to cart`,
          // });
          // toast.error(`${item.item_name} Added to cart`, {
          //   position: toast.POSITION.TOP_CENTER,
          //   hideProgressBar: true,
          //   autoClose: 3000
          // });
        } else {
          toast.error(
            `Item is not available in stock, so you cannot add to cart`,
            {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            }
          );
        }
      }
      this.props.onClose();
    } else {
      if (!_.isEmpty(item)) {
        let cart: any = [];
        this.props.getCart();
        let data: any = this.props.cart;
        if (!_.isEmpty(data)) {
          cart = JSON.parse(data);
        }
        let products: any = [];
        let MapObj: any = {};
        MapObj = {
          quantity: this.state.itemQuantity,
          price: this.state.selectedsize.mrp,
          id: item.menu_item_id,
          name: item.item_name,
          variant: this.state.selectedsize.size,
          category: this.props.GroupName ? this.props.GroupName : "",
        };
        products.push(MapObj);
        if (item.is_active == 1) {
          if (item.menu_item_id) {
            if (!_.isEmpty(cart)) {
              let found: any = "";
              let modifier_comparison = true;
              cart.forEach((element: any) => {
                if (element["item"]) {
                  if (element["item"].menu_item_id == item.menu_item_id) {
                    if (
                      this.state.selectedsize.size == element.selectedsize.size
                    ) {
                      let cartmodifiers = element["item"].modifiers;
                      let cartMakeCombos = element["item"].make_combo_options;

                      if (cartmodifiers !== undefined) {
                        Object.keys(cartmodifiers).map((key) => {
                          cartmodifiers[key].map((obj: any, index: any) => {
                            Object.keys(this.state.modifiers).map((key) => {
                              this.state.modifiers[key].map(
                                (item: any, index: any) => {
                                  if (obj.modifier_name == item.modifier_name) {
                                    if (obj.selected == item.selected) {
                                      return;
                                    } else {
                                      return (modifier_comparison = false);
                                    }
                                  }
                                }
                              );
                            });
                          });
                        });
                        if (modifier_comparison) {
                          Object.keys(cartmodifiers).map((key) => {
                            cartmodifiers[key].map((obj: any, index: any) => {
                              Object.keys(this.state.modifiers).map((key) => {
                                this.state.modifiers[key].map(
                                  (item: any, index: any) => {
                                    if (
                                      obj.modifier_name == item.modifier_name
                                    ) {
                                      if (obj.selected == item.selected) {
                                        obj.quantity += item.quantity;
                                      }
                                    }
                                  }
                                );
                              });
                            });
                          });
                          element.quantity =
                            element.quantity + this.state.itemQuantity;
                          element.totalItemPrice =
                            this.state.itemPrice * this.state.itemQuantity +
                            element.totalItemPrice;
                          return (found = 1);
                        }
                      } else {
                      }
                    }
                  }
                }
              });
              console.log("found", found);
              if (found == "") {
                const itemcart = {
                  item: item,
                  quantity: this.state.itemQuantity,
                  selectedsize: this.state.selectedsize,
                  price: Math.round(this.state.initialPrice),
                  totalItemPrice:
                    Math.round(this.state.itemPrice) * this.state.itemQuantity,
                  image: item.images.length > 0 ? item.images[0].image_url : "",
                };
                cart.push(itemcart);
              }
            } else {
              const itemcart = {
                item: item,
                quantity: this.state.itemQuantity,
                selectedsize: this.state.selectedsize,
                price: Math.round(this.state.initialPrice),
                totalItemPrice:
                  Math.round(this.state.itemPrice) * this.state.itemQuantity,
                image: item.images.length > 0 ? item.images[0].image_url : "",
              };

              cart.push(itemcart);
            }
          }
          console.log(cart);
          this.props.saveCart(cart);
          this.props.getCart();
          if (!_.isEmpty(this.state.modifiers)) {
            Object.keys(this.state.modifiers).map((key: any) => {
              {
                this.state.modifiers[key].map((element: any) => {
                  element.selected = false;
                });
              }
            });
          }
          this.setState({
            prevModPrice: 0,
            prevIndex: 0,
            prevMod: "",
            itemQuantity: 1,
          });
          // await Toast.fire({
          //   icon: "success",
          //   title: `${item.item_name} Added to cart`,
          // });
          // toast.error(`${item.item_name} Added to cart`, {
          //   position: toast.POSITION.TOP_CENTER,
          //   hideProgressBar: true,
          //   autoClose: 3000
          // });
        } else {
          toast.error(
            `Item is not available in stock, so you cannot add to cart`,
            {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            }
          );
        }
      }
      this.props.onClose();
    }
  };

  handleAddTopItem = async (item: any, combo: any) => {
    console.log("this.state.itemPrice before", this.state.itemPrice);
    // const Toast = Swal.mixin({
    //   toast: true,
    //   position: "top-right",
    //   iconColor: "white",
    //   customClass: {
    //     popup: "colored-toast",
    //   },
    //   showConfirmButton: false,
    //   timer: 1500,
    //   timerProgressBar: true,
    // });
    if (_.isEmpty(this.props.storeSelected)) {
      toast.error(`Please select location first`, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
      this.props.onClose();
      this.setState({ locationModal: true });
    } else {
      if (!_.isEmpty(item)) {
        let cart: any = [];
        this.props.getCart();
        let data: any = this.props.cart;
        if (!_.isEmpty(data)) {
          cart = JSON.parse(data);
        }
        let products: any = [];
        let MapObj: any = {};
        MapObj = {
          quantity: this.state.itemQuantity,
          price: this.state.selectedsize.mrp,
          id: item.menu_item_id,
          name: item.item_name,
          variant: this.state.selectedsize.size,
          category: this.props.GroupName ? this.props.GroupName : "",
          //variant: cartItem.selectedsize.size
        };
        products.push(MapObj);
        if (item.is_active == 1) {
          if (item.menu_item_id) {
            // console.log(item.combo_id, item.menu_item_id)
            let sizearray: any = JSON.parse(item.item_size);
            //     //if Item is menu_item
            if (!_.isEmpty(cart)) {
              let found: any = "";
              let modifier_comparison = true;
              cart.forEach((element: any) => {
                if (element["item"]) {
                  if (element["item"].menu_item_id == item.menu_item_id) {
                    if (sizearray[0].size == element.selectedsize.size) {
                      element.quantity += 1;
                      element.totalItemPrice =
                        sizearray[0].mrp * this.state.itemQuantity +
                        element.totalItemPrice;
                      found = 1;
                      return;
                    } else {
                      found = "";
                      return (modifier_comparison = false);
                    }
                  }
                }
              });
              // if(modifier_comparison) {
              //   cart.forEach((element: any) => {
              //     if (element["item"]) {
              //       if (element["item"].menu_item_id == item.menu_item_id) {
              //         if (sizearray[0].size== element.selectedsize.size) {
              //           element.quantity+= 1
              //           element.totalItemPrice = (sizearray[0].mrp * this.state.itemQuantity) + element.totalItemPrice;
              //         }
              //       }
              //     }
              //   });
              //   found = 1
              // }
              console.log("found", found);
              if (found == "") {
                const itemcart = {
                  item: item,
                  quantity: this.state.itemQuantity,
                  selectedsize: sizearray[0],
                  price: Math.round(sizearray[0].mrp),
                  totalItemPrice:
                    Math.round(sizearray[0].mrp) * this.state.itemQuantity,
                  image:
                    item.images && item.images.length > 0
                      ? item.images[0].image_url
                      : "",
                };
                cart.push(itemcart);
              }
            } else {
              const itemcart = {
                item: item,
                quantity: this.state.itemQuantity,
                selectedsize: sizearray[0],
                price: Math.round(sizearray[0].mrp),
                totalItemPrice:
                  Math.round(sizearray[0].mrp) * this.state.itemQuantity,
                image:
                  item.images && item.images.length > 0
                    ? item.images[0].image_url
                    : "",
              };

              cart.push(itemcart);
            }
          }

          this.props.saveCart(cart);
          this.props.getCart();
          if (!_.isEmpty(this.state.modifiers)) {
            Object.keys(this.state.modifiers).map((key: any) => {
              {
                this.state.modifiers[key].map((element: any) => {
                  element.selected = false;
                });
              }
            });
          }
          this.setState({
            prevModPrice: 0,
            prevIndex: 0,
            prevMod: "",
            itemQuantity: 1,
          });
          // await Toast.fire({
          //   icon: "success",
          //   title: `${item.item_name} Added to cart`,
          // });
          // toast.error(`${item.item_name} Added to cart`, {
          //   position: toast.POSITION.TOP_CENTER,
          //   hideProgressBar: true,
          //   autoClose: 3000
          // });
        } else {
          toast.error(
            `Item is not available in stock, so you cannot add to cart`,
            {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            }
          );
        }
      }
      this.state.make_combos.map((options: any) => {
        options.quantity = 0;
        options.option.selected = false;
      });
      this.setState({ make_combos: [], prevMakeComboPrice: 0 });
      this.props.onClose();
    }
  };

  addModifier = (key: any, modifier_name: any) => {
    const { itemPrice, modifiers } = this.state;
    let basePrice = itemPrice;

    modifiers[key] = modifiers[key].map((item: any) => {
      if (item.modifier_name === modifier_name) {
        // Toggle the selection state of the clicked modifier
        const isSelected = !item.selected;
        item.selected = isSelected;

        // Update base price based on selection state
        basePrice += isSelected
          ? item.modifier_sale_price * item.quantity
          : -item.modifier_sale_price * item.quantity;
        item.price = isSelected ? item.modifier_sale_price : 0;
        item.quantity = isSelected ? 1 : item.quantity;
      } else if (item.modifier_type === "single") {
        // If modifier type is single and it's not the clicked modifier, ensure only one modifier can be selected
        item.selected = false;
        item.price = 0;
        item.quantity = 1;
      }
      return item;
    });

    this.setState({
      itemPrice: basePrice,
      modifiers: { ...modifiers },
    });
  };

  incrementQuantity(key: any, modName: any) {
    let { modifiers, itemPrice } = this.state;
    modifiers[key].map((item: any, index: any) => {
      if (item.modifier_name === modName) {
        if (!item.selected) {
          item.selected = true;
          item.quantity = 1;
        } else {
          item.quantity += 1;
        }
        this.setState({
          modifiers: modifiers,
          itemPrice: parseInt(itemPrice) + item.modifier_sale_price,
        });
      }
    });
  }

  decrementQuantity(key: any, modName: any) {
    let { modifiers, itemPrice } = this.state;
    modifiers[key].map((item: any, index: any) => {
      if (item.modifier_name === modName) {
        if (item.quantity > 1) {
          item.quantity -= 1;
          this.setState({
            modifiers: modifiers,
            itemPrice: parseInt(itemPrice) - item.modifier_sale_price,
          });
        } else if (item.quantity === 1) {
          item.selected = false;
          this.setState({
            modifiers: modifiers,
            itemPrice: parseInt(itemPrice) - item.modifier_sale_price,
          });
        }
      }
    });
  }

  incrementChoiceQuantity(key: any, modName: any, limit: any, itemsize: any) {
    let { comboDetails, itemPrice } = this.state;
    let maxLimit = parseInt(limit);
    let reachedLimit: any = 0;
    const found = comboDetails[key].findIndex(
      (element: any) => element.quantity == limit
    );
    comboDetails[key].map((item: any, index: any) => {
      reachedLimit += parseInt(item.quantity);
    });
    if (found === -1) {
      if (reachedLimit < maxLimit) {
        comboDetails[key].map((item: any, index: any) => {
          if (
            item.item_name == modName &&
            JSON.parse(item.size).size == itemsize.size
          ) {
            item.quantity += 1;
            reachedLimit += 1;
            this.setState({
              comboDetails: comboDetails,
              itemPrice: itemPrice + item.extra_price,
            });
          }
        });
      } else {
        toast.error(`Maximum quantity selected for ${key}`, {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      }
    } else if (found >= 0) {
      toast.error(`Maximum quantity selected for ${key}`, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
    if (reachedLimit === maxLimit) {
      this.setState({ comboQuantityReached: true });
    } else {
      this.setState({ comboQuantityReached: false });
    }
  }

  decrementChoiceQuantity(key: any, modName: any, limit: any, itemsize: any) {
    let { comboDetails, itemPrice } = this.state;
    let reachedLimit: any = 0;
    let maxLimit = parseInt(limit);
    comboDetails[key].map((item: any, index: any) => {
      reachedLimit += parseInt(item.quantity);
      console.log(reachedLimit);
    });
    comboDetails[key].map((item: any, index: any) => {
      console.log(item);
      if (
        item.item_name == modName &&
        JSON.parse(item.size).size == itemsize.size &&
        item.quantity > 0
      ) {
        item.quantity -= 1;
        reachedLimit -= 1;
        this.setState({
          comboDetails: comboDetails,
          itemPrice: itemPrice - item.extra_price,
        });
      }
      if (reachedLimit === maxLimit) {
        this.setState({ comboQuantityReached: true });
      } else {
        this.setState({ comboQuantityReached: false });
      }
    });
  }
  decrementItemQuantity = () => {
    if (this.state.itemQuantity > 1) {
      this.setState({ itemQuantity: this.state.itemQuantity - 1 });
    }
  };

  IncrementItemQuantity = () => {
    this.setState({ itemQuantity: this.state.itemQuantity + 1 });
  };
  CloseComponent = () => {
    let { modifiers } = this.state;
    Object.keys(modifiers).map((key: any) => {
      modifiers[key].map((element: any) => {
        element.selected = false;
      });
    });

    this.props.onClose();

    this.setState({
      itemQuantity: 1,
      modifiers: {},
      make_combos: [],
      itemPrice: this.state.initialPrice,
      selectedMakeACombos: null,
      prevMakeComboPrice: 0,
    });
  };
  goToItem() {
    let { itemData } = this.props;
    let newnames = itemData.item_group_id.group_name.replace(/\s+/g, "-");
    let replacedName =
      newnames &&
      newnames
        .replace(/[`~!@#$%^*()_|+=?;:'",.<>\{\}\[\]\\\/]/gi, "")
        .toLowerCase();
    let newName = replacedName.replace("-&", "");
    console.log(replacedName, "replaced");
    this.props.history.push({
      pathname: `/groupitem/${newName}`,
      search: window.location.search,
    });
  }

  handleUpdate = async (e: any) => {
    this.setState({ itemPrice: e });
  };

  handleStateUpdate = async (finalPrice: any, prevPrice: any) => {
    this.setState({ itemPrice: finalPrice, prevMakeComboPrice: prevPrice });
  };

  onPickupOpenModal = () => {
    this.setState({ locationModal: !this.state.locationModal });
  };

  render() {
    let { itemData } = this.props;
    // console.log("itemData", itemData);
    let { modifiers, selectedsize } = this.state;
    const token = sessionStorage.getItem("token");

    return (
      <div>
        <Modal
          open={this.props.open}
          onClose={this.CloseComponent}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box
            sx={{
              ...style,
              width: [370, 400, 500],
              border: "none",
              borderRadius: "15px",
              className: "modal_height",
            }}
          >
            <section style={{ marginBottom: "10px" }}>
              <div
                className="modal-content-container position-relative"
                style={{ fontFamily: "Playfair Display" }}
              >
                <div className="closeIcon" onClick={this.CloseComponent}>
                  <CloseIcon className="icon_close" />
                </div>
                <div className="productModal-body row">
                  <div className="col-12">
                    <div>
                      {!_.isEmpty(itemData) && selectedsize.image_url && (
                        <div style={{ cursor: "pointer" }}>
                          <img
                            className="productModal-image"
                            loading="lazy"
                            alt={
                              selectedsize.alt_text
                                ? selectedsize.alt_text
                                : itemData.item_name
                            }
                            src={`${BASE_URL}${selectedsize.image_url}`}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="productModal-detail">
                      <h2
                        className="productModal-name"
                        style={{ fontWeight: 600 }}
                      >
                        {!_.isEmpty(itemData) && itemData.item_name}{" "}
                      </h2>
                      <p
                        className="productModal-description"
                        style={{ marginTop: "-15px" }}
                      >
                        {!_.isEmpty(itemData) ? selectedsize.description : ""}
                      </p>
                    </div>
                  </div>

                  <div className="productModal-more">
                    <div className="productModal-body1">
                      {!_.isEmpty(itemData) &&
                        itemData.allergens &&
                        itemData.allergens.length > 0 && (
                          <p
                            className="productModal-name"
                            style={{ fontSize: "20px", marginBottom: "-3px" }}
                          >
                            Allergens
                          </p>
                        )}
                      <ul className="productModal-allerges-list">
                        {!_.isEmpty(itemData) &&
                          itemData.allergens &&
                          itemData.allergens.map((items: any) => {
                            return (
                              <li className="d-flex flex-row text-custom">
                                <div className="orange-disk">
                                  <DoneIcon className="check-icon" />
                                </div>
                                {items.label}
                              </li>
                            );
                          })}
                      </ul>
                      {!_.isEmpty(itemData) &&
                        itemData.modifiers &&
                        Object.keys(modifiers).map((key, index) => {
                          return (
                            <div>
                              <p
                                className="productModal-name"
                                style={{
                                  fontSize: "20px",
                                  marginBottom: "-5px",
                                  paddingTop: "20px",
                                }}
                              >
                                {key}
                              </p>
                              {modifiers[key].map((items: any) => (
                                <div
                                  className="row combo-details"
                                  key={items.modifier_name}
                                >
                                  <div
                                    className="col-7"
                                    onClick={() =>
                                      this.addModifier(key, items.modifier_name)
                                    }
                                  >
                                    <div className="ml-4 d-flex align-items-center">
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          padding: "10px 0px",
                                        }}
                                      >
                                        {items.modifier_type === "single" ? (
                                          <input
                                            checked={items.selected}
                                            className="form-check-input"
                                            type="radio"
                                            onChange={() => {}}
                                          />
                                        ) : (
                                          <input
                                            checked={items.selected}
                                            className="form-check-input"
                                            type="checkbox"
                                            onChange={() => {}}
                                          />
                                        )}
                                        <label className="form-check-label">
                                          <p className="productModal-single-item mb-0">
                                            {items.modifier_name}
                                            {items.modifier_sale_price ===
                                            0 ? null : (
                                              <span className="productModal-index">
                                                {items.modifier_sale_price +
                                                  " " +
                                                  priceunit +
                                                  ""}
                                              </span>
                                            )}
                                          </p>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div
              style={{
                display: "block",
                position: "absolute",
                bottom: "-20px",
                zIndex: 10,
                width: "100%",
                background: "white",
              }}
              className="shadow-custom-product-modal"
            >
              <div
                className="container"
                style={{ position: "relative", left: "20px" }}
              >
                <div className="row mb-1  ">
                  <div className="col-md-3 col-4 pm-cm-add-to-cart-mbl">
                    <div className="productModal-cart-outer-quantitys">
                      <div className="productModal-cart-product-icons-add-to-cart">
                        <img
                          onClick={() => this.decrementItemQuantity()}
                          src={
                            process.env.PUBLIC_URL + "/assets/images/minus.svg"
                          }
                          alt="order-check"
                          className="modal_btns"
                          style={{ height: "30px", cursor: "pointer" }}
                        />
                        <span
                          style={{ marginLeft: "12px", marginRight: "13px" }}
                        >
                          <strong className="font_custom">
                            {this.state.itemQuantity}
                          </strong>
                        </span>
                        <img
                          onClick={() => this.IncrementItemQuantity()}
                          src={
                            process.env.PUBLIC_URL + "/assets/images/plus.svg"
                          }
                          alt="order-check"
                          className="modal_btns"
                          style={{
                            height: "30px",
                            cursor: "pointer",
                            position: "relative",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8 col-7 ml-md-2 ml-5">
                    <div className="productModal-cart-outer-div">
                      <button
                        className={`productModal-cart-btn ${
                          this.props.timeOver ? "disable-button" : ""
                        }`}
                        onClick={() => this.handleAdd(this.props.itemData)}
                      >
                        <span style={{ color: "azure" }}>Add to Cart</span>
                        <div>
                          <span style={{ color: "azure", marginLeft: "15px" }}>
                            {priceunit +
                              Math.round(
                                this.state.itemQuantity * this.state.itemPrice
                                // + this.state.extraCharges
                              )}
                          </span>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    itemData: state.login.itemData,
    comboData: state.login.comboData,
    cart: state.login.cartData,
    GroupName: state.login.GroupName,
    topItemsList: state.login.topItemsList,
    storeSelected: state.login.storeSelected,
    timeOver: state.search.timerOver,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    getCart: function () {
      dispatch(getCart());
    },
    saveCart: function (cart: any[]) {
      dispatch(saveCart(cart));
    },
    getComboDetailList: function (id: any[]) {
      dispatch(getComboDetailList(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductModal);

import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { BASE_URL, priceunit } from "../../../ApiManager/client-config";
import {
  cartToggle,
  favouriteAdd,
  getComboDetailList,
  getItemDetail,
  menuGroupsList,
} from "../../../Redux/Actions";
import "./Page.css";
import { toast } from "react-toastify";
import LoaderSkeleton from "../../FeatureMenuProducts/skeleton";
import ProductModal from "../../ProductModal/ProductModal";
import _ from "lodash";
import "../../Categories/categories.css";
import "../../FeatureMenuProducts/featureProducts.css";
import CartToggler from "../../CartToggler/CartToggler";
import { useTranslation } from "react-i18next";
import { RootState } from "../../../Redux/store";
import MobileCategory from "./MobileCategory";
function Home(props: any) {
  const { t } = useTranslation();
  const [selectItem, setSeletItem] = useState({});

  const [open, setOpen] = useState(false);
  const timeOver = useSelector((state: RootState) => state.search.timerOver);
  const closeTime = useSelector((state: RootState) => state.search.CloseTime);
  useEffect(() => {
    let v = "";
    let doc = document as any;
    if (open) {
      doc.getElementsByTagName("html")[0]
        ? (doc.getElementsByTagName("html")[0].style.overflow = "hidden")
        : (v = "");
    } else {
      doc.getElementsByTagName("html")[0]
        ? (doc.getElementsByTagName("html")[0].style.overflow = "auto")
        : (v = "");
    }
  }, [open]);
  const onOpenModal = () => {
    setOpen(true);
    // if (typeof window != "undefined" && window.document) {
    //   document.body.style.overflow = "hidden";
    // }
  };
  const onCloseModal = () => setOpen(false);

  const selectedItem = (item: any, event: any) => {
    onOpenModal();
    event.preventDefault();
    if (item.menu_item_id > 0) {
      props.getItemDetail(item.menu_item_id);
    }
    setSeletItem(item);
  };

  const renderCartButton = (data: any) => {
    let cart: any = JSON.parse(data);
    // console.log("cartSidebar", cart);
    let totalItemQuantity = 0;
    cart.map((item: any) => {
      totalItemQuantity += item.quantity;
    });
    return (
      <div className="cart-hover">
        <i className="fa fa-cart-plus"></i>
        <span className="badge-circle custom-default-green-button">
          {totalItemQuantity}
        </span>
      </div>
    );
  };
  const calcTotal = (data: any) => {
    let sum = 0;
    data.forEach((item: any) => {
      sum += parseInt(item.totalItemPrice);
    });
    return Math.round(sum);
  };
  let cartData: any = props.cart;
  const handleFavourite = (group: any, id: any, type: any, isFav: any) => {
    if (isFav) {
      console.log("do nothing");
    } else {
      let token = sessionStorage.getItem("token");
      let data: any = {
        group_id: group,
      };
      if (type == "combo") {
        Object.assign(data, { combo_id: id });
      }

      if (type == "item") {
        Object.assign(data, { menu_item_id: id });
      }
      if (token) {
        props.favouriteAdd(
          data,
          props.storeSelected ? props.storeSelected.store_id : null
        );
      } else {
        toast.error("Sign in to add your favourite items", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      }
    }
  };
  return (
    <>
      <div
        className={`container mbl-menu-cntr mt-10 ${
          timeOver || closeTime ? "paddingExtraproducts" : ""
        }`}
      >
        <div className="row">
          <MobileCategory onClick={setSeletItem} />
          <div className="col-lg-12 col-md-8 col-sm-12 mt-3 d-md-block d-none">
            {props.groupsData && props.groupsData.length == 0 ? (
              <LoaderSkeleton />
            ) : (
              <>
                {props.featuredProducts &&
                  props.featuredProducts.length > 0 &&
                  props.filteredItems &&
                  props.filteredItems.length == 0}
                <>
                  {props.filteredItems && props.filteredItems.length == 0 && (
                    <section>
                      <div style={{ position: "absolute" }}></div>
                      <div
                        className="container"
                        style={{
                          maxWidth: "100%",
                          fontFamily: "Playfair Display",
                          marginTop: "-40px",
                        }}
                      >
                        <p
                          className={`group_reference ${
                            timeOver || closeTime
                              ? "hm-featured-heading-over"
                              : "hm-featured-heading"
                          }`}
                        >
                          {t("home.featureProducts")}
                        </p>
                        <div className="row row-tablet-width">
                          {props.featuredProducts &&
                            props.featuredProducts.map(
                              (featurep: any, index: any) => (
                                <div
                                  className="col-lg-4 col-sm-12 col-md-6"
                                  key={index}
                                  style={{ marginBottom: "17px" }}
                                >
                                  <div id="sister" className="hm-card-custom">
                                    <div className="row">
                                      <div className="col-8">
                                        <div
                                          onClick={(e) =>
                                            selectedItem(featurep, e)
                                          }
                                        >
                                          <h5 className="card-product-name">
                                            <a className="">
                                              {featurep.combo_id > 0
                                                ? featurep.combo_name.length >
                                                  24
                                                  ? `${featurep.combo_name.substring(
                                                      0,
                                                      24
                                                    )}...`
                                                  : featurep.combo_name
                                                : featurep.item_name.length > 24
                                                ? `${featurep.item_name.substring(
                                                    0,
                                                    24
                                                  )}...`
                                                : featurep.item_name}
                                            </a>
                                          </h5>
                                          <p className="card-product-desc">
                                            {featurep.item_description
                                              ? featurep.item_description
                                                  .length > 75
                                                ? `${featurep.item_description.slice(
                                                    0,
                                                    70
                                                  )}....`
                                                : featurep.item_description
                                              : ""}
                                          </p>
                                          <span className="card-product-price">
                                            {featurep.mrp > 0
                                              ? priceunit + featurep.mrp
                                              : priceunit +
                                                featurep.combo_mrp_price}
                                          </span>
                                        </div>
                                      </div>
                                      <div
                                        className="col-4"
                                        onClick={(e) =>
                                          selectedItem(featurep, e)
                                        }
                                      >
                                        {featurep.menu_item_id &&
                                        featurep.menu_item_id !== "" ? (
                                          <img
                                            className="product-image"
                                            style={{ borderRadius: 5 }}
                                            src={`${BASE_URL}${featurep.image_url}`}
                                          />
                                        ) : (
                                          <img
                                            alt={
                                              featurep.alt_text ||
                                              "alt needs to be added"
                                            }
                                            style={{ borderRadius: 5 }}
                                            src={`${BASE_URL}${featurep.image}`}
                                          />
                                        )}
                                      </div>
                                    </div>
                                    <div className="d-flex justify-content-between mt-1"></div>
                                  </div>
                                </div>
                              )
                            )}
                        </div>
                      </div>
                    </section>
                  )}
                </>
                {props.filteredItems && props.filteredItems.length == 0
                  ? props.groupsData &&
                    props.groupsData.map((group: any, index: any) => {
                      const currentGroup = props.groupList.find(
                        (glData: any) => glData?.group_id === group?.group_id
                      );
                      return (
                        <>
                          {
                            <section id={group.group_id}>
                              <div
                                id={`category${group.group_id}`}
                                className="container"
                                style={{
                                  maxWidth: "100%",
                                  fontFamily: "Playfair Display,ui-sans-serif",
                                }}
                              >
                                <p
                                  className="hm-featured-heading group_reference"
                                  style={{ marginTop: "0px" }}
                                >
                                  {" "}
                                  {group.data.length > 0
                                    ? currentGroup?.group_name
                                    : ""}{" "}
                                </p>
                                <section
                                  className="hm-featured-products-section pt-0"
                                  id={`category${group.group_id}`}
                                >
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "-140px",
                                    }}
                                  ></div>
                                  <div
                                    className="row row-tablet-width margin_left_tab"
                                    style={{ width: "-webkit-fill-available" }}
                                  >
                                    {group.data &&
                                      group.data.length > 0 &&
                                      group.data.map(
                                        (item: any, index: any) => (
                                          <div
                                            className="col-lg-4 col-sm-12 col-md-6"
                                            key={index}
                                            style={{ marginBottom: "17px" }}
                                          >
                                            <div
                                              id="sister"
                                              className="hm-card-custom"
                                            >
                                              <div className="row">
                                                <div className="col-8">
                                                  <div
                                                    onClick={(e) =>
                                                      selectedItem(item, e)
                                                    }
                                                  >
                                                    <h5 className="card-product-name">
                                                      <a className="">
                                                        {item.combo_id > 0
                                                          ? item.combo_name
                                                              .length > 24
                                                            ? `${item.combo_name.substring(
                                                                0,
                                                                24
                                                              )}...`
                                                            : item.combo_name
                                                          : item.item_name
                                                              .length > 24
                                                          ? `${item.item_name.substring(
                                                              0,
                                                              24
                                                            )}...`
                                                          : item.item_name}
                                                      </a>
                                                    </h5>
                                                    {(item.discount_price &&
                                                      item.order_channel ==
                                                        "online" && (
                                                        <>
                                                          <span className="card-product-price">
                                                            {item.item_size
                                                              ? priceunit +
                                                                Math.round(
                                                                  JSON.parse(
                                                                    item.item_size
                                                                  )[0]
                                                                    .discount_price
                                                                )
                                                              : priceunit +
                                                                Math.round(
                                                                  item.discount_price
                                                                )}{" "}
                                                          </span>{" "}
                                                          &nbsp;
                                                          <span
                                                            style={{
                                                              color: "#d60000",
                                                              textDecoration:
                                                                "line-through",
                                                            }}
                                                            className="card-product-price discount-styles"
                                                          >
                                                            {item.item_size
                                                              ? priceunit +
                                                                JSON.parse(
                                                                  item.item_size
                                                                )[0].mrp
                                                              : priceunit +
                                                                item.combo_mrp_price}{" "}
                                                          </span>
                                                        </>
                                                      )) ||
                                                      (item.item_size &&
                                                        JSON.parse(
                                                          item.item_size
                                                        )[0].discount_price &&
                                                        JSON.parse(
                                                          item.item_size
                                                        )[0].order_channel ==
                                                          "online" && (
                                                          <>
                                                            <span className="card-product-price">
                                                              {item.item_size
                                                                ? priceunit +
                                                                  Math.round(
                                                                    JSON.parse(
                                                                      item.item_size
                                                                    )[0]
                                                                      .discount_price
                                                                  )
                                                                : priceunit +
                                                                  Math.round(
                                                                    item.discount_price
                                                                  )}{" "}
                                                            </span>{" "}
                                                            &nbsp;
                                                            <span
                                                              style={{
                                                                textDecoration:
                                                                  item.item_size &&
                                                                  JSON.parse(
                                                                    item.item_size
                                                                  )[0]
                                                                    .discount_price
                                                                    ? "line-through"
                                                                    : "none",
                                                                color:
                                                                  item.item_size &&
                                                                  JSON.parse(
                                                                    item.item_size
                                                                  )[0]
                                                                    .discount_price
                                                                    ? "#d60000"
                                                                    : "#000",
                                                              }}
                                                              className="card-product-price discount-styles"
                                                            >
                                                              {item.item_size
                                                                ? priceunit +
                                                                  JSON.parse(
                                                                    item.item_size
                                                                  )[0].mrp
                                                                : priceunit +
                                                                  item.combo_mrp_price}{" "}
                                                            </span>
                                                          </>
                                                        ))}
                                                    {item.discount_price ==
                                                      null &&
                                                      item.item_size ==
                                                        null && (
                                                        <>
                                                          <span className="card-product-price">
                                                            {priceunit +
                                                              item.combo_mrp_price}
                                                          </span>
                                                        </>
                                                      )}
                                                    <p className="card-product-desc">
                                                      {item.item_size
                                                        ? JSON.parse(
                                                            item.item_size
                                                          )[0].description
                                                          ? JSON.parse(
                                                              item.item_size
                                                            )[0].description
                                                              .length > 75
                                                            ? `${JSON.parse(
                                                                item.item_size
                                                              )[0].description.slice(
                                                                0,
                                                                70
                                                              )}....`
                                                            : JSON.parse(
                                                                item.item_size
                                                              )[0].description
                                                          : ``
                                                        : ""}
                                                      {item.combo_id > 0 &&
                                                        (item.combo_description
                                                          ? item
                                                              .combo_description
                                                              .length > 75
                                                            ? `${item.combo_description.slice(
                                                                0,
                                                                70
                                                              )}....`
                                                            : item.combo_description
                                                          : ``)}
                                                    </p>
                                                    {item.item_size &&
                                                      JSON.parse(
                                                        item.item_size
                                                      )[0].discount_price ==
                                                        null && (
                                                        <>
                                                          <span className="card-product-price">
                                                            {priceunit +
                                                              JSON.parse(
                                                                item.item_size
                                                              )[0].mrp}
                                                          </span>
                                                        </>
                                                      )}
                                                  </div>
                                                </div>
                                                <div
                                                  className="col-4"
                                                  onClick={(e) =>
                                                    selectedItem(item, e)
                                                  }
                                                >
                                                  {item.menu_item_id &&
                                                  item.menu_item_id !== "" ? (
                                                    <img
                                                      style={{
                                                        borderRadius: 5,
                                                      }}
                                                      className="product-image"
                                                      src={`${BASE_URL}${
                                                        JSON.parse(
                                                          item.item_size
                                                        )[0].image_url
                                                      }`}
                                                    />
                                                  ) : (
                                                    <img
                                                      alt={
                                                        item.alt_text ||
                                                        "alt needs to be added"
                                                      }
                                                      style={{
                                                        borderRadius: 5,
                                                      }}
                                                      className="product-image"
                                                      src={`https://www.server.bamboo.rizq.no/bb00c5c0-68b6-11ed-a5ca-0ff88da379b6-salads_variant_0-2022-11-20103554.jpeg`}
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                              <div className="d-flex justify-content-between mt-1"></div>
                                            </div>
                                            <div
                                              id="brother"
                                              className="background-effect-p"
                                            ></div>
                                          </div>
                                        )
                                      )}
                                  </div>
                                </section>
                              </div>
                            </section>
                          }
                        </>
                      );
                    })
                  : props.filteredItems &&
                    props.filteredItems.map((group: any, index: any) => {
                      const currentGroup = props.groupList.find(
                        (glData: any) => glData?.group_id === group?.group_id
                      );
                      return (
                        <>
                          {
                            <section>
                              <div
                                id={`category${group.group_id}`}
                                className="container"
                              >
                                <p
                                  className="hm-featured-heading group_reference"
                                  style={{ marginTop: "0px" }}
                                >
                                  {" "}
                                  {group.data.length > 0
                                    ? currentGroup?.group_name
                                    : ""}{" "}
                                </p>
                                <section
                                  className="hm-featured-products-section pt-0"
                                  id={`category${group.group_id}`}
                                >
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "-140px",
                                    }}
                                  ></div>
                                  <div className="container">
                                    <div className="row row-tablet-width margin_left_tab">
                                      {group.data &&
                                        group.data.length > 0 &&
                                        group.data.map(
                                          (item: any, index: any) => (
                                            <div
                                              className="col-lg-6 col-sm-12 col-md-6"
                                              key={index}
                                              style={{ marginBottom: "17px" }}
                                            >
                                              <div
                                                id="sister"
                                                className="hm-card-custom"
                                                onClick={(e) =>
                                                  selectedItem(item, e)
                                                }
                                              >
                                                <div className="row">
                                                  <div className="col-8">
                                                    <h5 className="card-product-name">
                                                      <a className="">
                                                        {item.combo_id > 0
                                                          ? item.combo_name
                                                              .length > 16
                                                            ? `${item.combo_name.substring(
                                                                0,
                                                                16
                                                              )}...`
                                                            : item.combo_name
                                                          : item.item_name
                                                              .length > 16
                                                          ? `${item.item_name.substring(
                                                              0,
                                                              16
                                                            )}...`
                                                          : item.item_name}
                                                      </a>
                                                    </h5>
                                                    {(item.discount_price &&
                                                      item.order_channel ==
                                                        "online" && (
                                                        <>
                                                          <span className="card-product-price">
                                                            {item.item_size
                                                              ? priceunit +
                                                                Math.round(
                                                                  JSON.parse(
                                                                    item.item_size
                                                                  )[0]
                                                                    .discount_price
                                                                )
                                                              : priceunit +
                                                                Math.round(
                                                                  item.discount_price
                                                                )}{" "}
                                                          </span>
                                                          <span className="card-product-price discount-styles">
                                                            {item.item_size
                                                              ? priceunit +
                                                                JSON.parse(
                                                                  item.item_size
                                                                )[0].mrp
                                                              : priceunit +
                                                                item.combo_mrp_price}{" "}
                                                          </span>
                                                        </>
                                                      )) ||
                                                      (item.item_size &&
                                                        JSON.parse(
                                                          item.item_size
                                                        )[0].discount_price &&
                                                        JSON.parse(
                                                          item.item_size
                                                        )[0].order_channel ==
                                                          "online" && (
                                                          <>
                                                            <span className="card-product-price mr-2">
                                                              {item.item_size
                                                                ? priceunit +
                                                                  Math.round(
                                                                    JSON.parse(
                                                                      item.item_size
                                                                    )[0]
                                                                      .discount_price
                                                                  )
                                                                : priceunit +
                                                                  Math.round(
                                                                    item.discount_price
                                                                  )}{" "}
                                                            </span>
                                                            <span
                                                              style={{
                                                                color:
                                                                  "#d60000",
                                                                textDecoration:
                                                                  "line-through",
                                                              }}
                                                              className="card-product-price discount-styles"
                                                            >
                                                              {item.item_size
                                                                ? priceunit +
                                                                  JSON.parse(
                                                                    item.item_size
                                                                  )[0].mrp
                                                                : priceunit +
                                                                  item.combo_mrp_price}{" "}
                                                            </span>
                                                          </>
                                                        )) ||
                                                      (item.item_size &&
                                                        JSON.parse(
                                                          item.item_size
                                                        )[0].discount_price &&
                                                        JSON.parse(
                                                          item.item_size
                                                        )[0].order_channel ==
                                                          "online" && (
                                                          <>
                                                            <span className="card-product-price discount-styles">
                                                              {item.item_size
                                                                ? priceunit +
                                                                  JSON.parse(
                                                                    item.item_size
                                                                  )[0].mrp
                                                                : priceunit +
                                                                  item.combo_mrp_price}{" "}
                                                            </span>
                                                          </>
                                                        ))}
                                                    {item.discount_price ==
                                                      null &&
                                                      item.mrp == null && (
                                                        <>
                                                          {item.combo_id >
                                                            0 && (
                                                            <span className="card-product-price">
                                                              {priceunit +
                                                                item.combo_mrp_price}
                                                            </span>
                                                          )}
                                                        </>
                                                      )}
                                                    <p className="card-product-desc">
                                                      {item.item_size
                                                        ? JSON.parse(
                                                            item.item_size
                                                          )[0].description
                                                          ? JSON.parse(
                                                              item.item_size
                                                            )[0].description
                                                              .length > 75
                                                            ? `${JSON.parse(
                                                                item.item_size
                                                              )[0].description.slice(
                                                                0,
                                                                70
                                                              )}....`
                                                            : JSON.parse(
                                                                item.item_size
                                                              )[0].description
                                                          : ``
                                                        : ""}
                                                      {item.combo_id > 0 &&
                                                        (item.combo_description
                                                          ? item
                                                              .combo_description
                                                              .length > 75
                                                            ? `${item.combo_description.slice(
                                                                0,
                                                                70
                                                              )}....`
                                                            : item.combo_description
                                                          : ``)}
                                                    </p>
                                                    {item.mrp &&
                                                      JSON.parse(item.mrp)[0]
                                                        .discount_price ==
                                                        null && (
                                                        <>
                                                          <span className="card-product-price">
                                                            {priceunit +
                                                              JSON.parse(
                                                                item.mrp
                                                              )[0].mrp}
                                                          </span>
                                                        </>
                                                      )}
                                                  </div>
                                                  <div className="col-4">
                                                    {item.menu_item_id &&
                                                    item.menu_item_id !== "" ? (
                                                      <img
                                                        alt={
                                                          JSON.parse(
                                                            item.item_size
                                                          )[0].alt_text
                                                            ? JSON.parse(
                                                                item.item_size
                                                              )[0].alt_text
                                                            : item.item_name
                                                        }
                                                        style={{
                                                          borderRadius: 5,
                                                        }}
                                                        className="product-image"
                                                        src={`${BASE_URL}${
                                                          JSON.parse(
                                                            item.item_size
                                                          )[0].image_url
                                                        }`}
                                                      />
                                                    ) : (
                                                      <img
                                                        alt={
                                                          item.alt_text ||
                                                          "alt needs to be added"
                                                        }
                                                        style={{
                                                          borderRadius: 5,
                                                        }}
                                                        className="product-image"
                                                        src={`https://www.server.bamboo.rizq.no/bb00c5c0-68b6-11ed-a5ca-0ff88da379b6-salads_variant_0-2022-11-20103554.jpeg`}
                                                      />
                                                    )}
                                                  </div>
                                                </div>
                                                <div className="d-flex justify-content-between mt-1"></div>
                                              </div>
                                              <div
                                                id="brother"
                                                className="background-effect-p"
                                              ></div>
                                            </div>
                                          )
                                        )}
                                    </div>
                                  </div>
                                </section>
                              </div>
                            </section>
                          }
                        </>
                      );
                    })}
              </>
            )}
          </div>
        </div>
        <ProductModal open={open} onClose={onCloseModal} item={selectItem} />
        {!_.isEmpty(cartData) && JSON.parse(cartData).length > 0 && (
          <div
            className="p_sticky_view_cart"
            onClick={() => {
              props.cartToggleAction(false);
            }}
          >
            <div className="d-flex justify-content-between p_sticky_view_cart_btn">
              <div className="view_basket_icon">
                {" "}
                {renderCartButton(props.cart)}
              </div>
              <span>View cart</span>
              <span>
                {"NOK"}
                {calcTotal(JSON.parse(cartData))}
              </span>
            </div>
          </div>
        )}
        {/* {!_.isEmpty(cartData) && JSON.parse(cartData).length > 0 && (
          <button
            id="fixedbutton"
            type="button"
            onClick={() => {
              props.cartToggleAction(props.cartToggle == true ? false : true);
            }}
          >
            {renderCartButton(props.cart)}
          </button>
        )} */}
      </div>
    </>
  );
}
const mapStateToProps = (state: any) => {
  return {
    groupList: state.login.groupList,
    groupsData: state.login.groupsData,
    cartToggle: state.login.cartToggle,
    cart: state.login.cartData,
    featuredProducts: state.login.featuredProducts,
    filteredItems: state.login.filteredItems,
    storeSelected: state.login.storeSelected,
    addressData: state.login.addressData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    menuGroupsList: function (store_id: any) {
      dispatch(menuGroupsList(store_id));
    },
    getComboDetailList: function (id: any[]) {
      dispatch(getComboDetailList(id));
    },
    getItemDetail: function (id: any) {
      dispatch(getItemDetail(id));
    },
    cartToggleAction: (toggle: any) => {
      dispatch(cartToggle(toggle));
    },
    favouriteAdd: (data: any, store_id: any) => {
      dispatch(favouriteAdd(data, store_id));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);

import React, { Component } from "react";
import moment from "moment";
import "./cartToggler.css";
import { CartkProps } from "../Interface/loginInterface";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import Api from "../../ApiManager/index";
import { RefObject } from "react";
import CloseIcon from '@mui/icons-material/Close';
import OutsideClickHandler from "react-outside-click-handler";
import {
  cartOpenModal,
  setOpenTimerModal,
  setSelectedStoreName,
  setStoreCloseTime,
  setStoreOpenTime,
  setTimeOrder,
  setTimeOver,
} from "../../Redux/Reducers/searchReducer";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import EastIcon from "@mui/icons-material/East";
import { Modal, Box } from "@mui/material";

import {
  SaveDiscountValue,
  applyCoupon,
  cartToggle,
  findPickupStores,
  getCart,
  handleSpecialInstruction,
  resetCoupon,
  saveCart,
  saveOrderDateTime,
  saveOrderType,
  saveStore,
  saveTaxRate,
  setTabName,
} from "../../Redux/Actions/index";
import { connect } from "react-redux";
import _ from "lodash";
import { BASE_URL, priceunit } from "../../ApiManager/client-config";
// import { Modal } from "react-responsive-modal";
import ModeBar from "../modeBar";
import TimeupModal from "./TimeupModal";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 0,
  px: 0,
  pb: 0,
};



class CartToggler extends Component<
  CartkProps,
  {
    coupon_code: any;
    discountFlag: boolean;
    pickupOpen: boolean;
    orderType: any;
    tabNumber: any;
    taxRate: any;
    futureDate: any;
    futureTime: any;
    openModel: any;
    options: any;
    mode: any;
    setTable: any;
    isScrolled: Boolean;
    toggleActive: Boolean;
    setTime: any;
    cartOpening: boolean;
    storeOpen: boolean;
    storeName: any;
    openTime: any;
    closeTime: boolean;
    timeControl: any;
  }
> {
  itemTotal: any;
  tax: any;
  codeExeCount: number;
  discount: any;
  quantity: any;
  totalTax: any;
  couponDiscount: any;
  cartTotal: any;
  appliedCoupon: boolean;

  constructor(props: any) {
    super(props);
    this.state = {
      coupon_code: "",
      discountFlag: false,
      pickupOpen: false,
      orderType: "",
      tabNumber: 0,
      futureDate: new Date(),
      futureTime: "",
      taxRate: 0,
      openModel: false,
      options: [],
      mode: true,
      setTable: true,
      isScrolled: false,
      toggleActive: false,
      setTime: "",
      cartOpening: false,
      storeOpen: false,
      storeName: "",
      openTime: "",
      closeTime: false,
      timeControl: "",
    };
    this.itemTotal = 0;
    this.discount = 0;
    this.quantity = 0;
    this.totalTax = 0;
    this.codeExeCount = 0;
    this.couponDiscount = 0;
    this.appliedCoupon = false;
    this.handleChange = this.handleChange.bind(this);
    // this.cartTogglerRef = React.createRef<HTMLDivElement>();
  }

  private cartTogglerRef: RefObject<HTMLDivElement> = React.createRef();

  toggleState = () => {
    this.setState({ toggleActive: !this.state.toggleActive });
  };

  storeOpenState = () => {
    this.setState({
      storeOpen: !this.state.storeOpen,
    });
  };

  getTime = (value: any) => {
    this.props.setOrderTime(value);
    const selectedTime = value || "As soon as possible";

    localStorage.setItem("setTime", selectedTime);
    this.setState({ setTime: selectedTime }, () => {
      this.setState({ futureTime: value });
      this.setState({ toggleActive: false });
    });
    if (value != "As soon as possible") {
      this.props.saveOrderDateTime(new Date(), selectedTime, "orderLater");
    } else {
      sessionStorage.removeItem("futureDate");
    }
  };

  handleScroll = () => {
    const cartToggler = this.cartTogglerRef.current;
    const scrollThreshold = window.innerHeight * 1.1;

    if (window.scrollY > scrollThreshold && !this.state.isScrolled) {
      this.setState({ isScrolled: true });
    } else if (window.scrollY <= scrollThreshold && this.state.isScrolled) {
      this.setState({ isScrolled: false });
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.props.getCart();
    this.props.cartOpenModal();
    this.generateOptions();
    this.setState({ orderType: sessionStorage.getItem("orderType") });
    this.props.findPickupStores();
    if (this.props.orderType == "Dinein") {
      //set 15% VAT Tax
      this.props.saveTaxRate(0.25);
      sessionStorage.setItem("tax_percent", "25");
      sessionStorage.setItem("tax_rate", "0.25");
    }
    let orderTypeMode = localStorage.getItem("OrderTypeMode");
    let StoreName = localStorage.getItem("storeName");
    // this.setState({ storeName: StoreName });
    console.log("here is ordertype mode", orderTypeMode);
    // if(orderTypeMode === 'true'){
    //     this.setState({mode:true})
    // }else{
    //  this.setState({mode:false})
    // }
    // let tableNumber = localStorage.getItem('tableNumber');
    // this.setState({ tabNumber: tableNumber });

    let timePickup = localStorage.getItem("setTime");
    // this.setState({ setTime: timePickup });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleChange(event: any) {
    this.setState({ coupon_code: event.target.value });
  }

  ApplyCoupan = () => {
    this.appliedCoupon = true;
    let { coupon_code, discountFlag } = this.state;
    let { orderType, cart } = this.props;
    this.codeExeCount = 0;
    let Cart: any = cart;
    let discountItemFlag = false;
    let dataCart = JSON.parse(Cart);
    dataCart.forEach((item: any) => {
      if (item) {
        if (item.selectedsize.discount_price) {
          this.setState({ discountFlag: true });
          discountItemFlag = true;
        }
      }
    });
    if (coupon_code !== "" && discountItemFlag == false) {
      this.props.getCart();
      let token: any = sessionStorage.getItem("token");
      let sessionStore: any = sessionStorage.getItem("storeSelected");
      let parsedStore: any = sessionStore ? JSON.parse(sessionStore) : null;
      let data = {
        store_id: parsedStore ? parsedStore.store_id : null,
        coupon_code: coupon_code,
        order_mode: "online",
        order_channel: orderType == "Delivery" ? "delivery" : "pickup",
      };
      if (token) {
        let decoded: any = jwt_decode(token);
        Object.assign(data, { customer_id: decoded.customer.customer_id });
      }
      this.props.applyCoupon(data);
      this.calcTotal(dataCart);
      //   if(!_.isEmpty(this.props.coupon) && coupon_code != '') {
      //     toast.error("Coupon applied successfully")
      // }
    } else {
      //   toast.error("Coupon can not be applied on discounted items");
    }
  };

  incrementQuantity(currentIndex: any) {
    let cart: any = sessionStorage.getItem("cart");
    let Cart: any = cart;
    let dataCart = JSON.parse(Cart);
    if (dataCart[currentIndex].item) {
      let price = dataCart[currentIndex].selectedsize.discount_price
        ? Math.round(dataCart[currentIndex].selectedsize.discount_price)
        : dataCart[currentIndex].selectedsize.orderModePrice[0].mrp;
      dataCart.map((obj: any, index: any) => {
        if (currentIndex == index) {
          if (obj.item.modifiers) {
            const keys = Object.keys(obj.item.modifiers);
            if (keys.length > 0) {
              for (let i = 0; i < keys.length; i++) {
                const selectedModifiers = obj.item.modifiers[keys[i]].filter(
                  (item: any) => item.selected === true
                );
                selectedModifiers.forEach((item: any) => {
                  obj.totalItemPrice += item.modifier_sale_price;
                });
              }
            }

            // const keys = Object.keys(obj.item.modifiers);
            // if(keys.length > 0)
            // {
            //   for (let i = 0; i < keys.length; i++) {
            //     const key = keys[i];
            //     obj.item.modifiers[key].map((item: any, index: any) => {
            //       if(item.selected == true)
            //       {
            //         obj.totalItemPrice += item.modifier_sale_price;

            //       }
            //     }
            //   )}
            // }
          }
          obj.totalItemPrice = obj.totalItemPrice + price;
          obj.quantity += 1;
        } else {
          //dataCart.splice(currentIndex, 1);
          this.props.saveCart(dataCart);
          this.props.getCart();
        }
      });
      this.props.saveCart(dataCart);
      setTimeout(() => {
        this.props.getCart();
      }, 300);
    }
  }

  decrementQuantity(item: any, currentIndex: any) {
    let cart: any = sessionStorage.getItem("cart");
    let Cart: any = cart;
    let dataCart = JSON.parse(Cart);

    let products: any = [];
    let MapObj: any = {};
    //dataCart.forEach((cartItem:any) => {
    if (item.item) {
      MapObj = {
        quantity: 1,
        price: item.price,
        id: item.item.menu_item_id,
        name: item.item.item_name,
      };
    }
    products.push(MapObj);

    if (dataCart[currentIndex].item) {
      let price = dataCart[currentIndex].selectedsize.discount_price
        ? Math.round(dataCart[currentIndex].selectedsize.discount_price)
        : dataCart[currentIndex].selectedsize.orderModePrice[0].mrp;
      let makeAComboPrice = 0;
      dataCart.map((obj: any, index: any) => {
        if (currentIndex == index) {
          if (obj.quantity == 1) {
            dataCart.splice(currentIndex, 1);
            this.props.saveCart(dataCart);
            this.props.getCart();
          }
          const keys = Object.keys(obj.item.modifiers);
          if (keys.length > 0) {
            for (let i = 0; i < keys.length; i++) {
              const selectedModifiers = obj.item.modifiers[keys[i]].filter(
                (item: any) => item.selected === true
              );
              selectedModifiers.forEach((item: any) => {
                obj.totalItemPrice -= item.modifier_sale_price;
              });
            }
          }
          obj.totalItemPrice = obj.totalItemPrice - price - makeAComboPrice;
          obj.quantity -= 1;
        }
      });
      this.props.saveCart(dataCart);
      setTimeout(() => {
        this.props.getCart();
      }, 300);
    }
  }

  calcItemTotal = (carts: any) => {
    let sum = 0;
    carts.forEach((item: any) => {
      sum += parseInt(item.totalItemPrice);
    });
    this.itemTotal = sum;
    return sum;
  };

  calcTotalTax = () => {
    let totalTaxAmount: number = 0; // Use number type for better precision
    let orderType = this.props.orderType;

    if (orderType) {
      let data: any = sessionStorage.getItem("cart");
      let cart: any = JSON.parse(data);
      let tax_rate: number = parseFloat(this.props.taxRate); // Ensure tax_rate is a number

      for (let index = 0; index < cart.length; index++) {
        const element = cart[index].item;
        let taxAmount: any = 0;
        // let net_price: number = element.price / (1 + tax_rate);
        let net_price: any =
          (Number(cart[index].quantity) * parseFloat(element.price)) /
          (1 + Number(tax_rate));
        let net_p: any = parseFloat(net_price).toFixed(2);
        taxAmount = Number(cart[index].quantity) * element.price - net_p;
        taxAmount = parseFloat(taxAmount).toFixed(2);
        // taxAmount = element.price - net_price;

        // totalTaxAmount += parseFloat(cart[index].quantity) * taxAmount;
        totalTaxAmount += Number(taxAmount);

        if (cart[index].item.modifiers) {
          if (typeof cart[index].item.modifiers == "object") {
            Object.keys(cart[index].item.modifiers).map((key) => {
              cart[index].item.modifiers[key].map((item: any, _index: any) => {
                if (item.selected == true) {
                  // let mod_net_price: number =
                  //   item.modifier_sale_price / (1 + tax_rate);
                  let modTaxAmount: number = 0;
                  // item.modifier_sale_price - mod_net_price;
                  let mod_net_price: any =
                    (Number(cart[index].quantity) *
                      parseFloat(item.modifier_sale_price)) /
                    (1 + Number(tax_rate));
                  let mod_net_p: any = parseFloat(mod_net_price).toFixed(2);
                  modTaxAmount =
                    Number(cart[index].quantity) * item.modifier_sale_price -
                    Number(mod_net_p);
                  totalTaxAmount += Number(modTaxAmount);
                }
              });
            });
          }
        }
      }
    }

    // Round totalTaxAmount to two decimal places
    totalTaxAmount = parseFloat(totalTaxAmount.toFixed(2));

    this.props.SaveTaxValue(totalTaxAmount);

    return totalTaxAmount;
  };

  calcTotal = (data: any) => {
    let { deliveryfee, coupon } = this.props;
    this.couponDiscount = 0;
    this.discount = 0;
    let sum: any = 0;
    let sum2: any = 0;
    // let Coupon: string = coupon && coupon.channel;
    data.forEach((item: any) => {
      sum += parseInt(item.totalItemPrice);
      sum2 += parseInt(item.totalItemPrice);
    });
    let discountValue: any = 0;

    if (!_.isEmpty(coupon)) {
      if (coupon.type == "menu") {
        if (coupon.coupon_value !== 0) {
          this.couponDiscount = coupon.coupon_value;
          discountValue = coupon.coupon_value;
        } else {
          discountValue = (sum * coupon.percent) / 100;
          this.couponDiscount += discountValue;
        }
        if (this.couponDiscount === 0 && this.codeExeCount == 0) {
          toast.error("Coupon can not be applied on these items");
          this.props.resetCoupon({});
          this.codeExeCount = 1;
          this.appliedCoupon = false;
        }
      } else if (coupon.type == "group") {
        let groups_json: any = coupon.groups_json;
        let parsedGroupsJson: any = groups_json
          ? JSON.parse(groups_json)
          : null;
        data.forEach((item: any, index: any) => {
          parsedGroupsJson &&
            parsedGroupsJson.map((json: any) => {
              if (item.item) {
                if (
                  item.item.item_group_id == json.value ||
                  item.item.group_id == json.value
                ) {
                  if (coupon.coupon_value !== 0) {
                    discountValue =
                      discountValue + coupon.coupon_value * item.quantity;
                    this.couponDiscount += discountValue;
                    // item.totalItemPrice = item.totalItemPrice - discountValue;
                  } else {
                    discountValue =
                      (item.totalItemPrice * coupon.percent) / 100;
                    this.couponDiscount += discountValue;
                    // item.totalItemPrice = item.totalItemPrice - discountValue;
                  }
                }
              }
            });
        });
        if (this.couponDiscount === 0 && this.codeExeCount == 0) {
          toast.error("Coupon can not be applied on these items");
          this.props.resetCoupon({});
          this.codeExeCount = 1;
          this.appliedCoupon = false;
        }
      } else if (coupon.type == "item") {
        let CouponitemsArr = JSON.parse(coupon.items_json);
        data.forEach((item: any, index: any) => {
          CouponitemsArr.map((coupon_items: any) => {
            if (item.item && coupon_items.menu_item_id) {
              if (item.item.menu_item_id == coupon_items.menu_item_id) {
                if (coupon.coupon_value !== 0) {
                  discountValue =
                    discountValue + coupon.coupon_value * item.quantity;
                  this.couponDiscount += discountValue;
                  // item.totalItemPrice = item.totalItemPrice - coupon.coupon_value;
                } else {
                  discountValue = (item.totalItemPrice * coupon.percent) / 100;
                  this.couponDiscount += discountValue;
                  // item.totalItemPrice = item.totalItemPrice - discountValue;
                }
              }
            }
          });
        });
        if (this.couponDiscount === 0 && this.codeExeCount == 0) {
          toast.error("Coupon can not be applied on these items");
          this.props.resetCoupon({});
          this.codeExeCount = 1;
          this.appliedCoupon = false;
        }
      } else if (coupon.type == "store") {
        if (coupon.coupon_value !== 0) {
          this.couponDiscount = coupon.coupon_value;
          discountValue = coupon.coupon_value;
        } else {
          discountValue = (sum * coupon.percent) / 100;
          //   this.couponDiscount += discountValue;
          discountValue = (sum * coupon.percent) / 100;
          this.couponDiscount += discountValue;
        }
        if (this.couponDiscount === 0 && this.codeExeCount == 0) {
          toast.error("Coupon can not be applied on these items");
          this.props.resetCoupon({});
          this.codeExeCount = 1;
          this.appliedCoupon = false;
        }
      }

      if (!_.isEmpty(this.props.coupon) && this.appliedCoupon) {
        toast.error("Coupon applied successfully");
        this.appliedCoupon = false;
      }
    } else {
      this.discount = 0;
      this.couponDiscount = 0;
      this.cartTotal = 0;
    }
    if (
      this.props.orderType == "Pickup" ||
      this.props.orderType == "Qr-Pickup"
    ) {
      sum =
        this.props.pos_fee > 0
          ? sum - this.couponDiscount + parseInt(this.props.pos_fee)
          : sum - this.couponDiscount;
    }
    this.props.SaveDiscountValue(this.couponDiscount);
    this.cartTotal = Math.round(sum);
    if (this.props.taxDataCash.tax_type == 1) {
      sum = parseInt(sum) + this.totalTax;
    } else {
      sum = parseInt(sum);
    }
    // sum = parseInt(sum) + this.totalTax;
    let finalSum = _.isNaN(sum) ? sum2 : sum;
    return Math.round(finalSum);
  };

  calcTotalDiscount = (discount: any) => {
    return discount;
  };
  openMiniCartModel = () => {
    this.setState({ openModel: true });
    console.log("cart open");
  };
  goToCheckout = () => {
    let orderType: any = sessionStorage.getItem("orderType");
    localStorage.setItem("special_inst", this.props.special_ins);
    localStorage.setItem("storeName", this.state.storeName);
    localStorage.setItem("OrderTypeMode", this.state.mode);

    let selectedTime = this.state.timeControl;
    const currentTime = moment().format("hh:mm A");
    let selectedMoment = moment(selectedTime, "hh:mm: A");
    const timeDifferenceInMinutes = selectedMoment.diff(
      moment(currentTime, "hh:mm A"),
      "minutes"
    );
    console.log("here is time difference", timeDifferenceInMinutes);
    const minimumInterval = 10;

    if (this.props.orderType === "Pickup") {
      if (timeDifferenceInMinutes < minimumInterval) {
        this.props.setOpenTimerModal(true);

        return;
      } else {
        if (orderType) {
          this.props.setTabName(
            sessionStorage.getItem("token") ? "payment" : "checkout"
          );
        } else {
          this.onPickupOpenModal(true);
        }
      }
    } else {
      if (orderType) {
        this.props.setTabName(
          sessionStorage.getItem("token") ? "payment" : "checkout"
        );
      } else {
        this.onPickupOpenModal(true);
      }
    }
    this.props.cartToggleAction(false);
  };

  calculateOrderDiscount = (data: any) => {
    let discount = 0;
    data.forEach((item: any) => {
      if (item) {
        if (item.selectedsize.discount_price) {
          discount +=
            item.quantity *
            Math.round(
              item.selectedsize.mrp - item.selectedsize.discount_price
            );
        }
      }
    });
    return discount;
  };
  countSelectedModifiers(cart: any, keyToCount: any) {
    let selectedModifiersCount = 0;

    cart.forEach((item: any) => {
      if (item.item.selected && item.item.modifiers[keyToCount]) {
        selectedModifiersCount += item.item.modifiers[keyToCount].length;
      }
    });

    return selectedModifiersCount;
  }
  getModifierPrice = (modifiers: any, carts: any, quantity: number): any => {
    let value = Object.keys(modifiers).reduce((count, key) => {
      return (
        count +
        carts.item.modifiers[key].reduce((innerCount: number, item: any) => {
          return (
            innerCount + (item.selected ? Number(item.modifier_sale_price) : 0)
          );
        }, 0)
      );
    }, 0);
    return quantity * value;
  };

  cartItem = (data: any) => {
    let tax_rate: any = sessionStorage.getItem("tax_rate");
    if (!_.isEmpty(data)) {
      let cart: any[] = JSON.parse(data);
      if (cart.length > 0) {
        return (
          <div>
            <div className="d-md-none d-flex flex-col align-item-center mt-0 justify-content-between header_mbl_Cart">
              <span className="cart-heading" style={{ paddingLeft: "16px" }}>
                Your Cart
              </span>
              <div
                className="d-flex gap-3 align-items-center"
                style={{ paddingRight: "16px" }}
                onClick={this.props.cartOpenModal}
              >
                <span className="cart-cancel-btn">Close</span>
                <EastIcon
                  className="font_close_mobile"
                  style={{ fontSize: "20px" }}
                />
              </div>
            </div>
            <div
              className={
                window.innerWidth < 600 ? "ct-items-div p-3-1" : "ct-items-div"
              }
            >
              <div
                // style={{ overflowX: "hidden", overflowY: "hidden" }}
                className="cart-order-list"
              >
                {cart.map((carts, indexes) => (
                  <>
                    <div className="row" style={{ margin: "0px 0px" }}>
                      <div className="col-12">
                        <div className="row">
                          <div className="col-9 d-flex">
                            <img
                              className="cart-image"
                              src={`${BASE_URL}${carts.item.image_url}`}
                              alt="product image"
                            />
                            <p className="cart-product-name ml-2">
                              {!_.isEmpty(carts.item) &&
                                (carts.item.item_name.length > 32
                                  ? `${carts.item.item_name.substring(
                                      0,
                                      32
                                    )}...`
                                  : carts.item.item_name)}
                            </p>
                            {Object.keys(carts.item.modifiers).reduce(
                              (count, key) => {
                                return (
                                  count +
                                  carts.item.modifiers[key].reduce(
                                    (innerCount: any, item: any) => {
                                      return (
                                        innerCount + (item.selected ? 1 : 0)
                                      );
                                    },
                                    0
                                  )
                                );
                              },
                              0
                            ) > 0 && (
                              <p className="cart-choice-qty mt-2">
                                {Object.keys(carts.item.modifiers).reduce(
                                  (count, key) => {
                                    return (
                                      count +
                                      carts.item.modifiers[key].reduce(
                                        (innerCount: any, item: any) => {
                                          return (
                                            innerCount + (item.selected ? 1 : 0)
                                          );
                                        },
                                        0
                                      )
                                    );
                                  },
                                  0
                                ) + " choice item"}
                              </p>
                            )}
                          </div>
                          <div className="col-3">
                            <div className="product-price">
                              {`kr ${
                                Math.round(carts.price) * carts.quantity +
                                this.getModifierPrice(
                                  carts.item.modifiers,
                                  carts,
                                  carts.quantity
                                )
                              }`}
                            </div>

                            <div className="col-4">
                              <div className="cart-icons">
                                <img
                                  onClick={() =>
                                    this.decrementQuantity(carts, indexes)
                                  }
                                  src={
                                    process.env.PUBLIC_URL +
                                    (carts.quantity == 1
                                      ? "/assets/images/delete.svg"
                                      : "/assets/images/s1.svg")
                                  }
                                  alt="order-check"
                                  className="left-icon"
                                />
                                <span
                                  style={{
                                    width: carts.quantity > 9 ? "30px" : "30px",
                                  }}
                                  className="cart-cont"
                                >
                                  {carts.quantity}
                                </span>
                                <img
                                  onClick={() =>
                                    this.incrementQuantity(indexes)
                                  }
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/a1.svg"
                                  }
                                  alt="order-check"
                                  className="right-icon"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* {carts.selectedsize ? (
                            <p className="cart-product-price">
                              {carts.selectedsize.discount_price &&
                              carts.selectedsize.order_channel == "online" ? (
                                <></>
                              ) : (
                                <></>
                              )}
                            </p>
                          ) : (
                            <p className="cart-product-price">
                              {priceunit +
                                Math.round(carts.price) * carts.quantity}
                            </p>
                          )} */}
                      </div>
                    </div>
                    {<></>}

                    <hr
                      style={{ marginTop: "10px", marginBottom: "13px" }}
                    ></hr>
                  </>
                ))}
              </div>
              <hr className="divider mt-3"></hr>
              <div className="row mt-2" style={{ margin: "0px 0px" }}>
                <div className="col-12">
                  <p className="od">Order Details</p>
                </div>
                <div className="col-12">
                  {/* {this.props.orderType && (
                    <div
                      className="d-flex justify-content-between"
                      style={{ width: "452px", height: "37px" }}
                    >
                      <p className="cart-single-item-left">{"Order Mode"}</p>
                      <p>
                        <ModeBar
                          mode={this.state.mode}
                          handleModeClick={(e: any) => this.handleModeClick(e)}
                        />
                      </p>
                    </div>
                  )} */}
                </div>
                <div
                  className="col-12"
                  style={{
                    marginLeft: "-5px",
                    marginBottom: this.state.mode ? "5px" : "-5px",
                  }}
                >
                  <div
                    className="d-flex justify-content-between spi"
                    style={{ marginBottom: this.state.mode ? "-8px" : "0px" }}
                  >
                    {this.state.mode == true
                      ? "Select order time"
                      : "Table Number"}
                  </div>
                  <div className="col-12" style={{ marginLeft: "-8px" }}>
                    {this.state.mode == true ? (
                      this.props.orderType && (
                        <>
                          <div
                            style={{ marginBottom: "12px", marginTop: "12px" }}
                            className="cusomt_Input border-custom"
                            onClick={this.toggleState}
                          >
                            <span>
                              {this.state.setTime &&
                              this.state.setTime.length !== 0 ? (
                                <>
                                  <span
                                    style={{
                                      color: "black",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {this.state.setTime}
                                  </span>
                                </>
                              ) : (
                                "Select pickup time"
                              )}
                            </span>
                            <div onClick={this.toggleState}>
                              <KeyboardArrowDownIcon
                                style={{ fontSize: "25px", color: "#E64427" }}
                              />
                            </div>
                          </div>

                          {this.state.toggleActive && (
                            <div
                              className="custom_dropDown margin_custom_dropdown width_increase"
                              onClick={() => this.handleClick("Pickup")}
                            >
                              {this.state.options.map(
                                (time: any, index: any) => (
                                  <div
                                    key={index}
                                    onClick={() => this.getTime(time)}
                                    className="mt-1 mb-1 classHover"
                                    style={{
                                      color: "black",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {time}
                                  </div>
                                )
                              )}
                            </div>
                          )}
                        </>
                      )
                    ) : (
                      <p
                        className="cart-single-item-left"
                        style={{ marginBottom: "-20px" }}
                      >
                        <input
                          style={{
                            width: "455px",
                            marginBottom: "0",
                            left: "10px",
                          }}
                          id="round"
                          type="number"
                          value={this.state.tabNumber}
                          className="form-control input-font-size table_number border-custom"
                          name="table_number"
                          placeholder="Enter you table number"
                          onChange={(e) => this.tableHandler(e)}
                          required
                          ref={(input) => (this.tableInput = input)}
                        />
                      </p>
                    )}
                  </div>
                </div>

                <div
                  className="col-12 mb-1 mt-0"
                  style={{ marginLeft: "-5px" }}
                >
                  <div className="d-flex justify-content-between spi">
                    {"Select store"}
                  </div>
                  <div className="col-12">
                    <div
                      style={{ marginBottom: "12px", marginLeft: "-8px" }}
                      className="cusomt_Input border-custom"
                      onClick={this.storeOpenState}
                    >
                      {this.state.storeName.length !== 0 ? (
                        <span
                          className="blockstore"
                          style={{ color: "black", fontWeight: "500" }}
                        >
                          {this.state.storeName}
                        </span>
                      ) : (
                        <span style={{ color: "black", fontWeight: "500" }}>
                          Select Store
                        </span>
                      )}
                      <div onClick={this.storeOpenState}>
                        <KeyboardArrowDownIcon
                          style={{ fontSize: "25px", color: "#E64427" }}
                        />
                      </div>
                    </div>

                    {this.state.storeOpen && (
                      <div
                        className="custom_dropDown"
                        onClick={() => {
                          this.storeOpenState();
                        }}
                      >
                        {this.props.stores &&
                          this.props.stores.length > 0 &&
                          this.props.stores
                            .slice()
                            .reverse()
                            .map((store: any, index: any) => (
                              <div className="d-flex flex-column" key={index}>
                                {console.log("store", store)}
                                {store.store_name === "Uranienborg (Closed)" ? (
                                  <span
                                    className="mt-1 mb-1 classHover stop_temp"
                                    style={{
                                      color: "black",
                                      fontWeight: "500",
                                    }}
                                    onClick={() => {
                                      this.handleStoreSelect(store);
                                    }}
                                  >
                                    {store.store_name}{" "}
                                    {/* <span style={{ color: "red" }}>
            (temporarily close)
          </span> */}
                                  </span>
                                ) : (
                                  <span
                                    className="mt-1 mb-1 classHover"
                                    style={{
                                      color: "black",
                                      fontWeight: "500",
                                    }}
                                    onClick={() => {
                                      this.handleStoreSelect(store);
                                    }}
                                  >
                                    {store.store_name}
                                  </span>
                                )}
                              </div>
                            ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-12">
                  <div className="d-flex justify-content-between spi">
                    Special instructions
                    <p className="optional">{`(Optional)`}</p>
                  </div>
                  <textarea
                    defaultValue={this.props.special_ins}
                    className="form-control special-ins"
                    name="special_instructions"
                    onChange={this.props.handleSpecialInstruction}
                    style={{ marginTop: "-6px", lineHeight: "1.5" }}
                  ></textarea>
                </div>
              </div>
              <hr className="divider mt-2"></hr>
              <div className="row mt-2" style={{ margin: "0px 0px" }}>
                <div className="col-12">
                  <p className="od">Order Payment</p>
                </div>
                <div className="col-12">
                  <div className={`form-group cart-coupon-apply`}>
                    <div className="cart-subtotal">
                      <p className="cart-single-item-left">Total NOK</p>
                      <p className="cart-single-item-right">
                        {priceunit + this.calcItemTotal(cart)}
                      </p>
                    </div>
                    <React.Fragment>
                      <>
                        <div className="cart-subtotal">
                          {this.props.orderType == "Pickup" ? (
                            <p className="cart-single-item-left">
                              of which VAT: {"(" + 0.15 * 100 + "%)"}
                            </p>
                          ) : (
                            <p className="cart-single-item-left">
                              of which VAT: {"(" + tax_rate * 100 + "%)"}
                            </p>
                          )}
                          <p className="cart-single-item-right">
                            {_.isNaN(this.calcTotalTax())
                              ? priceunit + 0
                              : priceunit + this.calcTotalTax()}
                          </p>
                        </div>
                      </>
                    </React.Fragment>
                    {
                      !_.isEmpty(this.props.coupon) && (
                        <div className="cart-subtotal">
                          <p
                            style={{ color: "#d60000", fontWeight: "bold" }}
                            className="cart-single-item-right"
                          >
                            Discount/Voucher (
                            {this.props.coupon && this.props.coupon.coupon_code}
                            )
                          </p>
                          <p
                            style={{ color: "#d60000", fontWeight: "bold" }}
                            className="cart-single-item-right"
                          >
                            {this.props.coupon &&
                            this.props.coupon.coupon_value !== 0
                              ? `-${
                                  priceunit +
                                  Math.round(
                                    this.calcTotalDiscount(
                                      this.props.discountAmount
                                    )
                                  )
                                }`
                              : `-${
                                  priceunit +
                                  Math.round(
                                    this.calcTotalDiscount(
                                      this.props.discountAmount
                                    )
                                  )
                                }`}
                          </p>
                        </div>
                      )
                      // )
                    }
                    {this.calculateOrderDiscount(cart) > 0 && (
                      <div className="cart-subtotal">
                        <p
                          style={{ color: "#d60000", fontWeight: "bold" }}
                          className="cart-single-item-right"
                        >
                          Your Discount
                        </p>
                        <p
                          style={{ color: "#d60000", fontWeight: "bold" }}
                          className="cart-single-item-right"
                        >
                          {priceunit + this.calculateOrderDiscount(cart)}
                        </p>
                      </div>
                    )}
                    {/* <hr style={{ margin: "auto" }}></hr> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="chk-out-btn">
              <Link
                to={
                  sessionStorage.getItem("storeSelected") &&
                  sessionStorage.getItem("token") &&
                  this.props.orderType
                    ? "/payment"
                    : this.props.orderType
                    ? "/checkout"
                    : "#"
                }
                type="button"
                className={`btn btn-lg btn-block cart-buttons ${
                  (this.props.orderType === "Dinein" &&
                    (!this.state.tabNumber || !this.state.storeName)) ||
                  (this.props.orderType === "Pickup" &&
                    !this.state.storeName) ||
                  !this.state.setTime
                    ? "disabled-btn-checkout"
                    : ""
                }`}
                onClick={this.goToCheckout}
              >
                <span>
                  <span className="cart-checkout">
                    Go to checkout {priceunit}
                    {this.calcTotal(cart)}{" "}
                  </span>
                </span>
              </Link>
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <div className="empty-ct-items-div position-relative">
            <div className="closeIconMobile" onClick={() => {
                  this.props.cartToggleAction(false);
                  this.props.cartOpenModal();
                }}>
                    <CloseIcon onClick={this.props.cartOpenModal} />
                  </div>
              <div className="row">
                <div className="col-12 text-center d-flex justify-content-center align-items-center">
                  <div className="basket-icon">
                    {this.props.timeOver || this.props.closeTime ? (
                      <img
                        src={process.env.PUBLIC_URL + "/clock.png"}
                        width={150}
                      />
                    ) : (
                      <img
                        src={process.env.PUBLIC_URL + "/cart.png"}
                        width={150}
                      />
                    )}
                  </div>
                </div>
                <div
                  className="col-12 text-center"
                  style={{ marginTop: "-40px" }}
                >
                  <div className="empty-cart">
                    {this.props.timeOver || this.props.closeTime ? (
                      <>
                        We’ll be back <span>{this.state.openTime}</span>
                      </>
                    ) : (
                      "Your Cart is Empty"
                    )}
                  </div>
                </div>
                <div className="col-3"></div>
                <div className="col-6 text-center">
                  <div className="empty-cart-content">
                    {this.props.timeOver || this.props.closeTime
                      ? "The kitchen is closed right now"
                      : " Tap the button down below to Create an order"}
                  </div>
                </div>
                <div className="col-3"></div>
              </div>
            </div>
            <div className="chk-out-btn">
              <Link
                to={"/"}
                type="button"
                className={`btn btn-lg btn-block cart-buttons ${
                  this.props.timeOver || this.props.closeTime
                    ? "disabled-btn-checkout"
                    : ""
                }`}
                onClick={() => {
                  this.props.cartToggleAction(false);
                  this.props.cartOpenModal();
                }}
              >
                <span>
                  <span className="cart-checkout">Add products to cart</span>
                </span>
              </Link>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div>
          <div className="empty-ct-items-div position-relative">
          <div className="closeIconMobile" onClick={() => {
                  this.props.cartToggleAction(false);
                  this.props.cartOpenModal();
                }}>
                    <CloseIcon />
                  </div>
            <div className="row">
              <div className="col-12 text-center d-flex justify-content-center align-items-center">
                <div className="basket-icon">
                  {this.props.timeOver || this.props.closeTime ? (
                    <img
                      src={process.env.PUBLIC_URL + "/clock.png"}
                      width={150}
                    />
                  ) : (
                    <img
                      src={process.env.PUBLIC_URL + "/cart.png"}
                      width={150}
                      alt="cart"
                    />
                  )}
                </div>
              </div>
              <div className="col-12 text-center">
                <div className="empty-cart">
                  
                  {this.props.timeOver || this.props.closeTime ? (
                    <>
                      We’ll be back <span>{this.state.openTime}</span>
                    </>
                  ) : (
                    "Your Cart is Empty"
                  )}
                </div>
              </div>
              <div className="col-3"></div>
              <div className="col-6 text-center">
                <div className="empty-cart-content">
                  {this.props.timeOver || this.props.closeTime
                    ? "The kitchen is closed right now"
                    : " Tap the button down below to Create an order"}
                </div>
              </div>
              <div className="col-3"></div>
            </div>
          </div>
          <div className="chk-out-btn">
            <Link
              to={"/"}
              type="button"
              className={`btn btn-lg btn-block cart-buttons ${
                this.props.timeOver || this.props.closeTime
                  ? "disabled-btn-checkout"
                  : ""
              }`}
              onClick={() => {
                this.props.cartToggleAction(false);
                this.props.cartOpenModal();
              }}
            >
              <span>
                <span className="cart-checkout">Add products to cart</span>
              </span>
            </Link>
          </div>
        </div>
      );
    }
  };
  handleAdd = async (item: any) => {
    let cart: any = [];
    this.props.getCart();
    let data: any = this.props.cart;
    if (!_.isEmpty(data)) {
      cart = JSON.parse(data);
    }
    if (!_.isEmpty(item)) {
      if (item.menu_item_id) {
        let sizearray: any = JSON.parse(item.item_size);
        //     //if Item is menu_item
        if (!_.isEmpty(cart)) {
          let found: any = "";
          cart.forEach((element: any) => {
            if (element["item"]) {
              if (element["item"].menu_item_id == item.menu_item_id) {
                if (sizearray[0].size == element.selectedsize.size) {
                  element.quantity = element.quantity + 1;
                  element.totalItemPrice = Math.round(
                    element.price * element.quantity
                  );
                  return (found = 1);
                }
              }
            }
          });
          if (found == "") {
            const itemcart = {
              item: item,
              quantity: 1,
              selectedsize: sizearray[0],
              price: sizearray[0].discount_price
                ? Math.round(sizearray[0].discount_price)
                : Math.round(sizearray[0].mrp),
              totalItemPrice:
                sizearray[0].discount_price &&
                sizearray[0].order_channel == "online"
                  ? Math.round(sizearray[0].discount_price)
                  : Math.round(sizearray[0].mrp),
              subTotal: Math.round(sizearray[0].price),
              image: item.image_url !== "" ? item.image_url : "",
            };
            cart.push(itemcart);
          }
        } else {
          const itemcart = {
            item: item,
            quantity: 1,
            selectedsize: sizearray[0],
            price: sizearray[0].discount_price
              ? Math.round(sizearray[0].discount_price)
              : Math.round(sizearray[0].mrp),
            totalItemPrice:
              sizearray[0].discount_price &&
              sizearray[0].order_channel == "online"
                ? Math.round(sizearray[0].discount_price)
                : Math.round(sizearray[0].mrp),
            subTotal: Math.round(sizearray[0].price),
            image: item.image_url !== "" ? item.image_url : "",
          };
          cart.push(itemcart);
        }
      }
    }
    this.props.saveCart(cart);
    this.props.getCart();
    toast.error(`${item.item_name && item.item_name} Added to cart`, {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
      autoClose: 1500,
    });
  };
  renderCartButton = (data: any) => {
    let cart: any = JSON.parse(data);
    let totalItemQuantity = 0;

    cart.map((item: any) => {
      totalItemQuantity += item.quantity;
    });
    return (
      <div className="cart-hover">
        <i className="fa fa-cart-plus"></i>
        <span className="badge-circle custom-default-green-button">
          {totalItemQuantity}
        </span>
      </div>
    );
  };
  setPickupOpen = (condition: any) => {
    this.setState({ pickupOpen: condition });
  };
  onPickupOpenModal = (value?: any) => {
    this.setPickupOpen(!this.state.pickupOpen);
  };
  generateOptions = () => {
    Api.get(`customer/store/${147}`).then((response) => {
      if (response.data.success) {
        const parsedStore = response.data.successResponse;
        if (parsedStore) {
          const store_open_time = parsedStore.store_open_time;

          const store_close_time = parsedStore.store_close_time;

          if (store_open_time && store_close_time) {
            this.props.setStoreOpenTime(store_open_time);
            this.props.setStoreCloseTime(store_close_time);
            console.log("inner if");
            const options: string[] = [];
            this.setState({ openTime: store_open_time });
            const currentHour = moment().hour();
            const currentMinute = moment().minute();
            const ClosehoursAsNumber = moment(
              store_close_time,
              "hh:mm A"
            ).hour();

            // Calculate the time until the next 5-minute boundary
            const timeUntilNextBoundary = 5 - (currentMinute % 5);
            let startHour = currentHour;
            let startMinute = (currentMinute + timeUntilNextBoundary + 25) % 60;
            // Set the initial start time 30 minutes from the current time
            startMinute = (currentMinute + timeUntilNextBoundary + 25) % 60;
            startHour += Math.floor(
              (currentMinute + timeUntilNextBoundary + 25) / 60
            );

            // Set the initial interval to 5 minutes
            let interval = 5;

            // Add the initial start time
            options.push(this.formatTime1(startHour, startMinute));

            while (startHour < ClosehoursAsNumber) {
              // Move to the next interval
              startMinute += interval;

              // Check if the interval should change every 5 minutes
              if (startMinute % 5 === 0) {
                interval = 5; // Change to a 5-minute interval
              }

              // Check if the hour needs to be incremented
              if (startMinute >= 60) {
                startMinute = 0;
                startHour += 1;

                // Add the current interval
                options.push(this.formatTime1(startHour, startMinute));
              } else {
                // Add the current interval
                options.push(this.formatTime1(startHour, startMinute));
              }
            }

            const lastInterval = options[options.length - 1];
            const lastIntervalMoment = moment(lastInterval, "hh:mm A");
            const closingTimeMoment = moment(
              store_close_time,
              "hh:mm A"
            ).subtract(20, "minutes");

            if (lastIntervalMoment.isAfter(closingTimeMoment)) {
              options.pop();
              options.pop();
              options.pop();
              options.pop();
            }

            this.setOptions([...options]);
          }
        }
      }
    });
  };

  formatTime1 = (hour: any, minute: any) => {
    return moment({ hour, minute }).format("HH:mm");
  };

  setOptions = (options: any) => {
    this.setState({ options: options });
  };
  tableInput: HTMLInputElement | null = null;
  tableHandler = (e: any) => {
    sessionStorage.setItem("table_number", e.target.value);
    const tabNumber = e.target.value;
    this.setState({ tabNumber: tabNumber });
    localStorage.setItem("tableNumber", tabNumber);
    sessionStorage.setItem("TableStorageNumber", tabNumber);
  };
  handleClick = (mode: any) => {
    this.setState({ orderType: mode });
    sessionStorage.setItem("orderType", mode);
    this.props.saveOrderType(mode);
    let orderMode = sessionStorage.getItem("orderType");
    sessionStorage.removeItem("orderNow");
    if (orderMode && orderMode == "Pickup") {
      //set 15% VAT Tax
      this.props.saveTaxRate(0.15);
      sessionStorage.setItem("tax_percent", "15");
      sessionStorage.setItem("tax_rate", "0.15");
    }

    if (orderMode && orderMode == "Dinein") {
      const tabNumber = sessionStorage.getItem("table_number") || "";
      sessionStorage.removeItem("futureDate");
      this.setState({ tabNumber });
      this.setState({ mode: false });
      if (this.tableInput) {
        this.tableInput.value = tabNumber;
      }
      //set 25% VAT Tax
      this.props.saveTaxRate(0.25);
      sessionStorage.setItem("tax_percent", "25");
      sessionStorage.setItem("tax_rate", "0.25");
      // this.handleDineInRowClick("dinein", "on");
    }
  };
  handleStoreSelect = (value: any) => {
    console.log("store func", value);
    if (value) {
      try {
        this.props.setSelectedStoreName(value.store_name);
        this.setState({ storeName: value.store_name });
        this.props.saveStore(value);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
  };
  handleModeClick = (mode: any, IsFuture?: any) => {
    if (mode == "Pickup" && IsFuture) {
      // if (e == "on") {
      this.setState({ mode: true });
      this.setState({ setTable: false });
      this.props.saveOrderType(mode);
      this.props.saveTaxRate(0.15);
      sessionStorage.setItem("tax_percent", "15");
      sessionStorage.setItem("tax_rate", "0.15");
      // }
    }
    if (mode == "Pickup" && !IsFuture) {
      sessionStorage.removeItem("futureDate");
      sessionStorage.removeItem("futureTime");
      this.setState({ mode: true });
      this.setState({ setTable: false });
      this.props.saveOrderType(mode);
      this.props.saveTaxRate(0.15);
      sessionStorage.setItem("tax_percent", "15");
      sessionStorage.setItem("tax_rate", "0.15");
    }
    if (mode == "Dinein") {
      this.setState({ mode: false });
      this.setState({ setTable: true });
      const tabNumber = sessionStorage.getItem("tabNumber") || "";
      this.setState({ tabNumber });
      if (this.tableInput) {
        this.tableInput.value = tabNumber;
      }
      this.props.saveOrderType(mode);
      this.props.saveTaxRate(0.25);
      sessionStorage.setItem("tax_percent", "25");
      sessionStorage.setItem("tax_rate", "0.25");
    }
  };

  render() {
    let { cart } = this.props;
    let { cartOpening } = this.props;
    const handleOutsideClick = () => {
      this.props.cartToggleAction(false);
    };
    return (
      <>
        <OutsideClickHandler onOutsideClick={handleOutsideClick}>
          <div hidden={this.props.isOpenMenu === true ? false : true}>
            <div
              className="sidebar-menu"
              style={{ boxShadow: "0 24px 24px rgba(0, 0, 0, .2)" }}
            >
              <div className="cart-upper-heading">
                <span className="cart-heading">Your Cart</span>
                <div className="row d-block text-center position-relative">
                  <span
                    onClick={() => {
                      this.props.cartToggleAction(
                        this.props.cartToggle == true ? false : true
                      );
                    }}
                    className="cart-cancel-btn mr-4"
                  >
                    Close
                  </span>
                  <span className="mb-5">
                    <ArrowForwardIcon
                      className="font_icon_close"
                      style={{ fontSize: "23px" }}
                    />
                    {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M15.7071 8.70711C24.0976 8.32458 24.0976 7.68342 15.7071 7.29289L9.34315 0.928932C8.95262 0.538408 8.31946 0.538408 7.92893 0.928932C7.53841 1.31946 7.53841 1.95262 7.92893 2.34315L13.5858 8L7.92893 13.6569C7.53841 14.0474 7.53841 14.6805 7.92893 15.0711C8.31946 15.4624 8.95262 15.4624 9.34315 15.0711L15.7071 8.70711ZM0 9H15V7H0L0 9Z"
                      fill="#413434"
                    />
                  </svg> */}
                  </span>
                </div>
              </div>
              {this.cartItem(cart)}
            </div>
          </div>
        </OutsideClickHandler>
        {/* {!_.isEmpty(cart) && JSON.parse(cart).length > 0 && (
          <>
            <div
              className="p_sticky_view_cart"
              onClick={() => this.openMiniCartModel()}
            >
              <div onClick={() => this.openMiniCartModel()} className="d-flex justify-content-between p_sticky_view_cart_btn">
                <div className="view_basket_icon">
                  {this.renderCartButton(cart)}
                </div>
                <span className="font_custom_cart">View cart</span>
                <span className="font_custom_cart">
                  {"NOK"} {this.calcTotal(JSON.parse(cart))}
                </span>
              </div>
            </div>


          </>

        )} */}

        {/* {!_.isEmpty(cart) && JSON.parse(cart).length > 0 && (
          <div
            ref={this.cartTogglerRef}
            className={`${
              this.state.isScrolled
                ? "cart_bar_scroll d-flex flex-row justify-content-between"
                : "cart_bar d-flex flex-row justify-content-between"
            } `}
            onClick={() => this.openMiniCartModel()}
          >
            <div className="view_basket_icon">
              {this.renderCartButton(cart)}
            </div>
            <span className="font_custom_cart">View cart</span>
            <span className="font_custom_cart">
              {"NOK"} {this.calcTotal(JSON.parse(cart))}
            </span>
          </div>
        )} */}

        <TimeupModal />
        {/* 
        <Modal
          open={this.props.cartOpening}
          onClose={this.props.cartOpenModal}
          center
          closeOnOverlayClick={false}
          closeOnEsc={false}
          closeIconId="mbl-cart-model-close-btn"
          classNames={{ modal: "customModal-mobile" }}
        >
          {this.cartItem(cart)}
        </Modal> */}

        <Modal
          open={this.props.cartOpening}
          onClose={this.props.cartOpenModal}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box
            sx={{
              ...style,
              width: [370, 400, 400],
              border: "none",
              borderRadius: "10px",
            }}
            className="modal_container"
          >
            
            {this.cartItem(cart)}
          </Box>
        </Modal>

        {/* <div className="cart_bar">
          <p>this is new cart button</p>
        </div> */}
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    cart: state.login.cartData,
    cartOpening: state.search.cartModal,
    orderType: state.login.orderType,
    special_ins: state.login.special_instructions,
    storeSelected: state.login.storeSelected,
    deliveryfee: state.login.deliveryfee,
    paymentMethod: state.login.paymentMethod,
    taxAmount: state.login.taxAmount,
    taxDataCash: state.login.taxDataCash,
    taxDataCard: state.login.taxDataCard,
    pos_fee: state.login.pos_fee,
    discountAmount: state.login.discountAmount,
    cartToggle: state.login.cartToggle,
    coupon: state.login.coupon,
    futureDate: state.login.orderDate,
    futureTime: state.login.orderTime,
    preOrLater: state.login.preOrLater,
    stores: state.login.pickupStoreList,
    timeOver: state.search.timerOver,
    taxRate: state.login.taxRate,
    closeTime: state.search.CloseTime,
    tabNumber:
      state.login.tabNumber ||
      parseInt(sessionStorage.getItem("tabNumber") || "0"),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getCart: function () {
      dispatch(getCart());
    },
    cartOpenModal: function () {
      dispatch(cartOpenModal(false));
    },
    saveCart: function (cart: any[]) {
      dispatch(saveCart(cart));
    },
    setTabName: (tabName: any) => {
      dispatch(setTabName(tabName));
    },
    handleSpecialInstruction: (event: any) => {
      dispatch(handleSpecialInstruction(event));
    },
    SaveTaxValue: function (taxAmount: any) {
      // dispatch(SaveTaxValue(taxAmount));
    },
    SaveDiscountValue: function (discountAmount: any) {
      dispatch(SaveDiscountValue(discountAmount));
    },
    cartToggleAction: (toggle: any) => {
      dispatch(cartToggle(toggle));
    },
    applyCoupon: (data: any) => {
      dispatch(applyCoupon(data));
    },
    resetCoupon: (obj: any) => {
      dispatch(resetCoupon(obj));
    },
    saveOrderType: function (orderType: any) {
      dispatch(saveOrderType(orderType));
    },
    saveTaxRate: function (taxRate: any) {
      dispatch(saveTaxRate(taxRate));
    },
    findPickupStores: function (searchString: any) {
      dispatch(findPickupStores(searchString));
    },
    saveStore: function (store: any) {
      dispatch(saveStore(store));
    },
    setStoreOpenTime: function (value: any) {
      dispatch(setStoreOpenTime(value));
    },
    setStoreCloseTime: function (value: any) {
      dispatch(setStoreCloseTime(value));
    },
    setTimeOver: function (value: any) {
      dispatch(setTimeOver(value));
    },
    setSelectedStoreName: function (value: any) {
      dispatch(setSelectedStoreName(value));
    },
    setOpenTimerModal: (toggle: any) => {
      dispatch(setOpenTimerModal(toggle));
    },
    setOrderTime: (timeValues: any) => dispatch(setTimeOrder(timeValues)),
    saveOrderDateTime: (date: any, time: any, preOrLater: any) => {
      dispatch(saveOrderDateTime(date, time, preOrLater));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartToggler);

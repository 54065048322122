import React, { useState, useRef, useEffect } from "react";
import "./sidebar.css";
import MenuIcon from "@mui/icons-material/Menu";
import StoreIcon from "@mui/icons-material/Store";
import {
  MenuItem,
  OpenHoursInteface,
  StoreName,
  dummyStores,
} from "./dummyData";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../Redux/store";
import { isSideBar } from "../../Redux/Reducers/searchReducer";
// import { Modal } from "react-bootstrap";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import jwtDecode from "jwt-decode";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styled from "@emotion/styled";
import Backdrop from "@mui/material/Backdrop";
import { set } from "lodash";
import axios from "axios";
import { group } from "console";
import index from "../Faq";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const StyledBackdrop = styled(Backdrop)({
  pointerEvents: "none",
});

const Sidebar = (position: any) => {
  const mapStyles = {
    height: "300px",
    width: "100%",
  };

  const defaultCenter = {
    lat: 40.7128, // Default to New York City latitude
    lng: -74.006, // Default to New York City longitude
  };

  const mapOptions = {
    center: { lat: 37.7749, lng: -122.4194 },
    zoom: 8,
  };

  const StoreName: StoreName[] = [
    {
      title: "Uranienborg",
      id: 0,
    },
    {
      title: "Bjerke",
      id: 1,
    },
    {
      title: "Sagene",
      id: 2,
    },
    {
      title: "Skøyen",
      id: 3,
    },
  ];

  interface DayTiming {
    day: string;
    opening: string;
    closing: string;
  }

  interface Store {
    store_id: number;
    store_name: string;
    store_email: string;
    isDays: number;
    daysTiming: DayTiming[] | 0 | undefined;
    is_active: number;
    store_open_time: string;
    store_close_time: string;
    is_closed: number;
  }

  const OpenHourData: OpenHoursInteface[] = [
    {
      title: "Monday",
      time: "09:00 - 20:00",
    },
    {
      title: "Tuesday",
      time: "09:00 - 20:00",
    },
    {
      title: "Wednesday",
      time: "09:00 - 20:00",
    },
    {
      title: "Thrusday",
      time: "09:00 - 20:00",
    },
    {
      title: "Friday",
      time: "09:00 - 20:00",
    },
    {
      title: "Saturday",
      time: "09:00 - 20:00",
    },
    {
      title: "Sunday",
      time: "09:00 - 20:00",
    },
  ];

  const menuItemData: MenuItem[] = [
    {
      id: 1,
      icon: "bi bi-journal",
      title: "Terms & Condition",
      link: "/conditions",
      linkTitle: "Show",
    },
    {
      id: 2,
      icon: "bi bi-journal",
      title: "Privacy Policy",
      link: "/PrivacyPolicy",
      linkTitle: "Show",
    },
  ];

  const [Stores, setStores] = useState<Store[]>([]);
  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://server.bamboo.rizq.no/customer/stores"
      );
      const data = response.data.successResponse;
      console.log("here is data", data);
      setStores(data);
    } catch (error) {
      // Handle error
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    console.log("here is timings", Stores);
  }, []);

  const isConsecutiveDays = (day1: string, day2: string) => {
    const daysOfWeek = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    const day1Index = daysOfWeek.indexOf(day1);
    const day2Index = daysOfWeek.indexOf(day2);
    return day1Index + 1 === day2Index;
  };

  const groupDaysBySameTimings = (
    daysTiming: DayTiming[] | null | undefined
  ) => {
    const groups: { days: DayTiming[] }[] = [];
    if (!daysTiming) return groups;

    let currentGroup: DayTiming[] = [daysTiming[0]];

    for (let i = 1; i < daysTiming.length; i++) {
      const currentDay = daysTiming[i];
      const previousDay = daysTiming[i - 1];

      if (
        currentDay.opening === previousDay.opening &&
        currentDay.closing === previousDay.closing &&
        isConsecutiveDays(previousDay.day, currentDay.day)
      ) {
        // If timings are the same and consecutive days, add to current group
        currentGroup.push(currentDay);
      } else {
        // If timings are different or not consecutive, start a new group
        groups.push({ days: currentGroup });
        currentGroup = [currentDay];
      }
    }

    groups.push({ days: currentGroup });
    return groups;
  };

  const displayDayTiming = (group: { days: DayTiming[] }) => {
    const firstDay = group.days[0];
    const lastDay = group.days[group.days.length - 1];

    return (
      <div
        key={firstDay.day}
        className="d-flex flex-row justify-content-start align-items-center main-content-div"
      >
        <i className="bi bi-clock phone-text mr-4"></i>
        <span className="phone-text">
          {firstDay.day} - {lastDay.day} {firstDay.opening} - {lastDay.closing}
        </span>
      </div>
    );
  };

  const [hourModal, setHourModal] = useState(false);
  // const [sessionStorage.getItem('token'), setUser] = useState(false);
  const [conactModal, setContactModal] = useState(false);
  const [cookiesModal, setCookiesModal] = useState(false);
  const [languageModal, setLanguageModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("English");
  const [logoutModal, setlogoutModal] = useState(false);
  const [activeStore, setActiveStore] = useState("");
  const [storeModal, setStoreModal] = useState(false);

  const [bjreke, setBjreke] = useState(false);
  const [skoyen, setSkoyen] = useState(false);
  const [sagene, setSagene] = useState(false);
  const [uranianborg, setUranianborg] = useState(false);

  const modalRef = useRef<HTMLDivElement | null>(null);
  const bjrekeRef = useRef<HTMLDivElement | null>(null);
  const uranianborgRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (storeModal && modalRef.current) {
      const offsetTop = uranianborg ? 1000 : 0; // Adjust the offset based on conditions

      modalRef.current.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  }, [storeModal, bjreke, skoyen, sagene, uranianborg]);

  const handleStoreModal = () => {
    setStoreModal(true);
  };

  const CloseStoreModal = () => {
    setStoreModal(false);
  };

  const currentDate = new Date();
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const currentDay = daysOfWeek[currentDate.getDay()];

  const CloseLogout = () => {
    setlogoutModal(false);
  };

  const handleLogout = () => {
    CloseLogout();
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("guestphone");
    localStorage.removeItem("orderId");
    window.location.href = "/";
  };
  const OpenLogoutModal = () => {
    setlogoutModal(true);
  };

  const handleLanguageChange = (event: any) => {
    setSelectedLanguage(event.target.value);
  };

  const LangOpen = () => setLanguageModal(true);
  const LangClose = () => setLanguageModal(false);

  const handleSave = () => {
    setLanguageModal(false);
  };

  const handleReset = () => {
    setLanguageModal(false);
    setSelectedLanguage("English");
  };

  const [store, setStore] = useState(false);
  const handleStore = () => {
    setStore(true);
  };

  const closeStore = () => {
    setStore(false);
  };

  const [dropdownBranch, setDropdownBranch] = useState(false);
  const [currentStore, setCurrentStore] = useState("");

  const handleCurrentStore = (storeName: any) => {
    setCurrentStore(storeName);
  };

  const handleDropdown = () => {
    setDropdownBranch(!dropdownBranch);
  };
  const handleStoreActive = (value: any) => {
    setActiveStore(value);
    setDropdownBranch(false);
  };
  const CloseDropdown = () => {
    setDropdownBranch(false);
  };

  const saveBranch = () => {
    setDropdownBranch(false);
    setStore(false);
  };

  const resetBranch = () => {
    setDropdownBranch(false);
    setStore(false);
  };

  useEffect(() => {
    const hasVisitedBefore = localStorage.getItem("hasVisitedBefore");

    if (!hasVisitedBefore) {
      setCookiesModal(true);
    }
  }, []);

  const CookiesOpen = () => {
    setCookiesModal(true);
  };

  const CookiesClose = () => {
    localStorage.setItem("hasVisitedBefore", "true");
    setCookiesModal(false);
  };

  const handleOverlayClick = (event: any) => {
    event.stopPropagation();
  };

  const conatactClose = () => setContactModal(false);
  const contactOpen = () => {
    setContactModal(true);
  };

  const HourOpen = () => {
    setHourModal(true);
  };

  const HourClose = () => setHourModal(false);

  const sideBar = useSelector((state: RootState) => state.search.SideBar);
  const dispatch = useDispatch();
  const handleSidebar = () => {
    dispatch(isSideBar(true));
  };

  const handleClose = () => {
    dispatch(isSideBar(!sideBar));
  };
  let user: any;
  let token: any = sessionStorage.getItem("token");
  if (token) {
    user = jwtDecode(token);
  }

  const maxLength = 20;

  return (
    <div className="container-sidebar">
      {/* <div className="icon-container" onClick={handleSidebar}>
        <MenuIcon className="icon-menu" style={{ fontSize: "25px", color:"black" }} />
      </div> */}

      {sideBar && (
        <div className={`sideBar-container ${position}`}>
          <div className="top_bar">
            {sessionStorage.getItem("token") ? (
              <div className="d-flex align-items-center">
                <div className="profile_container">
                  <i
                    className="fa-solid fa-user profile_icons"
                    style={{ color: "white", fontSize: "20px" }}
                  ></i>
                </div>
                <div
                  className="d-flex flex-column"
                  style={{ marginLeft: "8px" }}
                >
                  <span className="user_name">
                    {user.customer.first_name + user.customer.last_name}
                  </span>
                  <div>
                    <span className="user_email">
                      {user.customer.email_address.length > maxLength
                        ? user.customer.email_address.substring(
                            0,
                            maxLength - 8
                          ) +
                          "..." +
                          user.customer.email_address.substring(
                            user.customer.email_address.indexOf("@")
                          )
                        : user.customer.email_address}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <a href="/signup" className="sign-btn">
                  Sign Up
                </a>
                <a href="/login" className="signIn-btn">
                  Login
                </a>
              </div>
            )}
            <div
              onClick={handleClose}
              className="d-flex justify-content-center align-items-center"
            >
              {/* <i className="bi bi-arrow-left"></i> */}
              <ArrowBackIcon style={{ fontSize: "23px", color: "black" }} />
              <button className="close-sidebar">Close</button>
            </div>
          </div>
          <hr className="text-dark my-0 py-0 mb-2" />
          <div className="bottom-content d-flex flex-column">
            <div className="content_wrapper_link d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <i
                  className={`bi bi-person-fill content-left-active `}
                  style={{ marginRight: "5px", fontSize: "18px" }}
                ></i>
                <span className={`content-left-active `}>Profile</span>
              </div>
              {sessionStorage.getItem("token") ? (
                <a href="/profile" className={`content_right_active`}>
                  Edit
                </a>
              ) : (
                <a href="/login" className={`content_right_active`}>
                  Login
                </a>
              )}
              {/* <a
                href="/profile"
                className={` ${
                  sessionStorage.getItem("token")
                    ? "content_right_active"
                    : "disabled content_text_right"
                }`}
              >
                Edit
              </a> */}
            </div>
            <div className="content_wrapper_link d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <i
                  className={`bi bi-file-earmark-ruled-fill content-left-active`}
                  style={{ marginRight: "5px" }}
                ></i>
                <span className={`content-left-active`}>My Orders</span>
              </div>
              {sessionStorage.getItem("token") ? (
                <a href="/order" className={`content_right_active`}>
                  Show
                </a>
              ) : (
                <a href="/login" className={`content_right_active`}>
                  Login
                </a>
              )}
            </div>
            <div className="content_wrapper_link d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                {/* <i
                  className="bi bi-shop"
                  style={{
                    marginRight: "7px",
                    fontSize: "16px",
                    color: "black",
                  }}
                ></i> */}
                <StoreIcon
                  style={{
                    fontSize: "20px",
                    color: "black",
                    marginRight: "3px",
                  }}
                />
                <span className="content-left-active">Stores</span>
              </div>
              <span onClick={handleStoreModal} className="content_right_active">
                Show
              </span>
            </div>
            {/* <div className="content_wrapper_link d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
              <i className="fa-solid fa-clock" style={{marginRight:"5px", fontSize:"16px", color:"black"}}></i>
                <span className="content-left-active">Opening Hours</span>
              </div>
              <span onClick={HourOpen} className="content_right_active">
                Show
              </span>
            </div> */}
          </div>

          <hr className="py-0 mt-4 mb-2 text-dark" />
          <div className="bottom_content">
            <div className="content_wrapper_link d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <i
                  className="bi bi-globe content-left-active"
                  style={{ marginRight: "5px" }}
                ></i>

                <span className="content-left-active">{selectedLanguage}</span>
              </div>
              <span onClick={LangOpen} className="content_right_active">
                Change
              </span>
            </div>
            {/* <div className="content_wrapper_link d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <i
                  className="bi bi-person-lines-fill content-left-active"
                  style={{ marginRight: "5px" }}
                ></i>
                <span className="content-left-active">Contact</span>
              </div>
              <span onClick={contactOpen} className="content_right_active">
                Show
              </span>
            </div> */}
            {menuItemData.map((items) => (
              <div className="content_wrapper_link d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <i
                    className={`${items.icon} content-left-active `}
                    style={{ marginRight: "5px" }}
                  ></i>
                  <span className="content-left-active">{items.title}</span>
                </div>
                <a href={items.link} className="content_right_active">
                  {items.linkTitle}
                </a>
              </div>
            ))}
            <div className="content_wrapper_link d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <i
                  className="bi bi-cookie content-left-active"
                  style={{ marginRight: "5px" }}
                ></i>
                <span className="content-left-active">Cookies</span>
              </div>
              <span onClick={CookiesOpen} className="content_right_active">
                Show
              </span>
            </div>
          </div>
          {sessionStorage.getItem("token") && (
            <>
              <hr className="py-0 mt-4 mb-2 text-dark" />
              <div className="content_wrapper_link d-flex justify-content-between align-items-center">
                <div
                  className="d-flex align-items-center"
                  onClick={OpenLogoutModal}
                >
                  <i
                    className="fa-solid fa-arrow-right-from-bracket"
                    style={{ marginRight: "5px", color: "black" }}
                    onClick={OpenLogoutModal}
                  ></i>
                  <span className="logout_text">Logout</span>
                </div>
                {/* <span
                  className="content_right_active"
                  onClick={OpenLogoutModal}
                >
                  Change
                </span> */}
              </div>
            </>
          )}
          <div className="d-flex footer_sidebar">
            <span>Powered By </span>
            <img
              src={process.env.PUBLIC_URL + "/footer_side_logo.png"}
              style={{ marginLeft: "10px" }}
            />
          </div>
        </div>
      )}

      <Modal
        open={hourModal}
        onClose={HourClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: [357, 560, 560],
            border: "none",
            borderRadius: "10px",
          }}
        >
          <div className="custom_modal_hour_top">
            <h3 className="hour-title mb-2">Opening Hours</h3>
            <div className="close-btn-modal-open-hours" onClick={HourClose}>
              {" "}
              <i className="bi bi-x-lg font-weight-bold"></i>
            </div>
          </div>
          <hr className="hr-line-modal-contact" />
          <p id="child-modal-description">
            <div className="d-flex flex-column pt-3">
              {OpenHourData.map((item) => (
                <div className="d-flex flex-row justify-content-between custom-hours-padding">
                  <span
                    className={` ${
                      currentDay === item.title
                        ? "hours-text-active-day"
                        : "hour-text-days"
                    }`}
                  >
                    {item.title}
                  </span>
                  <span
                    className={`${
                      currentDay === item.title
                        ? "hours-text-active-hour"
                        : "hours-text-time"
                    }`}
                  >
                    {item.time}
                  </span>
                </div>
              ))}
              <hr className="hr-line-modal-contact-down" />
              <h3 className="hour-title pt-4 pb-0">Contact Information</h3>
              <div className="d-flex flex-row justify-content-between custom-hours-padding">
                <span className="hour-text-days">Number</span>
                <span className="hours-text-time">123456789</span>
              </div>
              <div className="d-flex flex-row justify-content-between custom-hours-padding">
                <span className="hour-text-days">Email</span>
                <span className="hours-text-time">123456789</span>
              </div>
              <div className="d-flex flex-row justify-content-between custom-hours-padding">
                <span className="hour-text-days">Address</span>
                <span className="hours-text-time">123456789</span>
              </div>
            </div>
          </p>
        </Box>
      </Modal>

      <Modal
        open={conactModal}
        onClose={conatactClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: [357, 560, 560],
            border: "none",
            borderRadius: "10px",
          }}
        >
          <div className="custom_modal_hour_top">
            <h3 className="hour-title">Contact Information</h3>
            <div className="close-btn-modal-open-hours" onClick={conatactClose}>
              {" "}
              <i className="bi bi-x-lg font-weight-bold"></i>
            </div>
          </div>
          <hr className="hr-line-modal-contact" />
          <p id="child-modal-description">
            <div className="d-flex flex-column ">
              <div className="d-flex flex-column mt-2">
                <div className="d-flex flex-row justify-content-between custom-hours-padding">
                  <span className="hour-text-days">Number</span>
                  <span className="hours-text-time">123456789</span>
                </div>
                <div className="d-flex flex-row justify-content-between custom-hours-padding">
                  <span className="hour-text-days">Email</span>
                  <span className="hours-text-time">123456789</span>
                </div>
                <div className="d-flex flex-row justify-content-between custom-hours-padding">
                  <span className="hour-text-days">Address</span>
                  <span className="hours-text-time">123456789</span>
                </div>
              </div>
            </div>
            <div className="mt-3 pt-2">
              <LoadScript googleMapsApiKey="AIzaSyBxx5-tQep4CHB2q2sppdQLl13eervY5Og">
                <GoogleMap
                  mapContainerStyle={mapStyles}
                  zoom={10}
                  center={defaultCenter}
                >
                  {/* Your map components go here */}
                </GoogleMap>
              </LoadScript>
            </div>
          </p>
        </Box>
      </Modal>

      <Modal
        open={cookiesModal}
        BackdropComponent={StyledBackdrop}
        onBackdropClick={handleOverlayClick}
        onClose={CookiesClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: [312, 400, 400],
            border: "none",
            borderRadius: "10px",
            paddingBottom: "5px",
          }}
        >
          <div>
            <h3 className="hour-title">Cookies</h3>
            {/* <div className="close-btn-modal" onClick={CookiesClose}>
              {" "}
              <i className="bi bi-x-lg font-weight-bold"></i>
            </div> */}
          </div>

          <p id="child-modal-description">
            <div className="d-flex flex-column ">
              <p className="cookeies-text mb-2">
                By using our website, you consent to the use of cookies, small
                files that enhance your experience on the site.
              </p>
              {/* <div className="d-flex flex-row justify-content-between cookies_multi_btn">
                <button className="cookeies-button-1" onClick={CookiesClose}>
                  All all cookies{" "}
                </button>
                <button className="cookeies-button-2" onClick={CookiesClose}>
                  Reject all cookies{" "}
                </button>
              </div> */}
              <button className="cookeies-button" onClick={CookiesClose}>
                I understand{" "}
              </button>
            </div>
          </p>
        </Box>
      </Modal>
      <Modal
        open={logoutModal}
        onClose={CloseLogout}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: [350, 420, 420],
            border: "none",
            borderRadius: "10px",
            paddingBottom: "5px",
            height: "200px",
          }}
        >
          <div>
            {/* <div className="close-btn-modal" onClick={CloseLogout}>
              {" "}
              <i className="bi bi-x-lg font-weight-bold"></i>
            </div> */}
          </div>

          <p id="child-modal-description">
            <div className="d-flex flex-column ">
              <p className="logout-text mb-2 mt-3">
                Are you sure you want to <br /> logout ?
              </p>
              <div className="d-flex justify-content-between buttonlogoutgap mt-1">
                <button className="lang-reset" onClick={CloseLogout}>
                  No{" "}
                </button>
                <button className="lang-save" onClick={handleLogout}>
                  Yes{" "}
                </button>
              </div>
            </div>
          </p>
        </Box>
      </Modal>

      <Modal
        open={languageModal}
        onClose={LangClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: [350, 500, 500],
            border: "none",
            borderRadius: "10px",
            paddingBottom: "5px",
            margin: "0px",
          }}
        >
          <div style={{ marginLeft: "24px", paddingBottom: "10px" }}>
            <div className="custom_modal_hour_top_lang">
              <h3 className="lang-heading">Language</h3>
              <div className="close-btn-modal" onClick={LangClose}>
                {" "}
                <i className="bi bi-x-lg font-weight-bold"></i>
              </div>
            </div>
            <hr className="hr-line-modal" />

            <div className="d-flex flex-column pt-4 ">
              <div className="d-flex flex-column">
                <p className="lang-subheading">Select One</p>
                <div className="d-flex flex-column">
                  <label className="d-flex align-items-center button_gap lang-text mb-2">
                    <input
                      type="radio"
                      name="language"
                      value="English"
                      // checked={selectedLanguage === "English"}
                      onChange={handleLanguageChange}
                    />
                    English
                  </label>
                  <label className="d-flex align-items-center button_gap lang-text">
                    <input
                      type="radio"
                      name="language"
                      value="Norwegian"
                      // checked={selectedLanguage === "Norwegian"}
                      onChange={handleLanguageChange}
                    />
                    Norwegian
                  </label>
                </div>
              </div>
              <div className="d-flex mt-2 button_gap justify-content-between pt-3">
                <button className="lang-reset-specail" onClick={handleReset}>
                  Reset
                </button>
                <button className="lang-save-special" onClick={handleSave}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={store}
        onClose={closeStore}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: [350, 500, 500],
            border: "none",
            borderRadius: "10px",
            paddingBottom: "5px",
            margin: "0px",
            height: [300],
          }}
        >
          <div style={{ marginLeft: "24px", paddingBottom: "10px" }}>
            <div className="custom_modal_hour_top">
              <h3 className="lang-heading">Branch</h3>
              <div className="close-btn-modal" onClick={closeStore}>
                {" "}
                <i className="bi bi-x-lg font-weight-bold"></i>
              </div>
            </div>
            <hr className="hr-line-modal" />

            <div className="d-flex flex-column pt-5 mt-2 position-relative">
              <div
                className="d-flex justify-content-between align-items-center dropdown_sidebar_store"
                onClick={handleDropdown}
              >
                <span className="dropdown_text">
                  {activeStore.length !== 0 ? activeStore : "Select"}
                </span>
                <i
                  className={`${
                    dropdownBranch
                      ? "bi bi-chevron-up dropdown_text"
                      : "bi bi-chevron-down dropdown_text"
                  }`}
                  onClick={handleDropdown}
                ></i>
              </div>

              {dropdownBranch && (
                <div className="dropdown_branch">
                  {StoreName.map((item, index) => (
                    <div
                      className={`d-flex justify-content-between align-items-center storeName-branch`}
                      onClick={() => handleStoreActive(item.title)}
                    >
                      <span className="content_right_active">{item.title}</span>
                      <span></span>
                    </div>
                  ))}
                </div>
              )}
              <div className="d-flex mt-5 button_gap justify-content-between pt-1">
                <button className="lang-reset-specail" onClick={resetBranch}>
                  Reset
                </button>
                <button className="lang-save-special" onClick={saveBranch}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={storeModal}
        onClose={CloseStoreModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: [350, 500, 520],
            border: "none",
            borderRadius: "10px",
            paddingBottom: "5px",
            paddingRight: "0px",
            paddingLeft: "0px",
            margin: "0px",
            height: [500],
          }}
          ref={modalRef}
        >
          <div className="custom_modal_hour_top mb-3">
            <h3 className="lang-heading ml-2">Stores</h3>
            <div
              className="close-btn-modal modal-stores-cross"
              onClick={CloseStoreModal}
            >
              {" "}
              <i className="bi bi-x-lg font-weight-bold"></i>
            </div>
          </div>
          <hr className="hr-store-modal" />
          <div className="custom-store-modal my-5">
            <div
              className={`d-flex justify-content-between align-items-center  ${
                bjreke ? "activeStoreName mb-1" : "dropdown_sidebar_store mb-3"
              }`}
              onClick={handleDropdown}
            >
              <span
                className={`${
                  bjreke ? "storeActiveText dropdown_text" : "dropdown_text"
                }`}
              >
                {Stores[2]?.store_name}
              </span>

              <span
                className={`${
                  bjreke
                    ? "dropdown_text_view storeActiveText"
                    : "dropdown_text_view"
                }`}
                onClick={() => {
                  setBjreke(!bjreke);
                }}
              >
                {bjreke ? "Close" : " View"}
              </span>
            </div>
            {bjreke && (
              <div
                className={`dropdown_branch ${bjreke ? "opened" : ""}`}
                ref={bjrekeRef}
              >
                <div className="d-flex flex-column justify-content-start align-items-start">
                  <div className="d-flex flex-row justify-content-start align-items-center main-content-div">
                    <i className="bi bi-telephone-fill phone-text mr-4"></i>
                    <span className="phone-text">922 66 989</span>
                  </div>
                  <div className="d-flex flex-row justify-content-start align-items-center main-content-div">
                    <i className="bi bi-envelope-fill phone-text mr-4"></i>
                    <span className="phone-text">{Stores[2].store_email}</span>
                  </div>
                  <div className="d-flex flex-row justify-content-start align-items-center main-content-div">
                    <i className="bi bi-sign-intersection-side-fill phone-text mr-4"></i>
                    <span className="phone-text">
                      Brobekkveien 31B, 0598 Oslo
                    </span>
                  </div>
                  {/* <div className="d-flex flex-row justify-content-start align-items-center main-content-div">
                    <i className="bi bi-clock phone-text mr-4"></i>
                    {Stores[2].isDays === 1 && Stores[2].daysTiming ? (
                      <span className="phone-text">
                        Man - Fre {Stores[2].daysTiming[0].opening} -{" "}
                        {Stores[2].daysTiming[0].closing}
                      </span>
                    ) : (
                      <span className="phone-text">
                        Man - Fre {Stores[2].store_open_time} -{" "}
                        {Stores[2].store_close_time}
                      </span>
                    )}
                  </div> */}
                  <>
                    {Stores[2].isDays === 1 && Stores[2].daysTiming ? (
                      <>
                        {/* Group days with same timings together */}
                        {groupDaysBySameTimings(Stores[2].daysTiming).map(
                          (group, index) => (
                            <div key={index}>{displayDayTiming(group)}</div>
                          )
                        )}
                      </>
                    ) : (
                      // If isDays is 0 or not provided, display a message or handle as needed
                      <span className="phone-text">
                        No timing information available
                      </span>
                    )}
                  </>
                  {/* <div className="d-flex flex-row justify-content-start align-items-center main-content-div">
                    <i className="bi bi-clock phone-text mr-4"></i>
                    {Stores[2].isDays === 1 && Stores[2].daysTiming ? (
                      <span className="phone-text">
                        Lør - Søn {Stores[2].daysTiming[5].opening} -{" "}
                        {Stores[2].daysTiming[5].closing}
                      </span>
                    ) : (
                      <span className="phone-text">
                        Lør - Søn {Stores[2].store_open_time} -{" "}
                        {Stores[2].store_close_time}
                      </span>
                    )}
                  </div> */}
                  <div className="d-flex flex-row justify-content-start align-items-center main-content-div">
                    <i className="bi bi-x-square-fill phone-text mr-4"></i>
                    <span className="phone-text">
                      Kjøkkenet stenger {Stores[2].store_close_time}
                    </span>
                  </div>
                </div>
              </div>
            )}

            <div
              className={`d-flex justify-content-between align-items-center  ${
                skoyen ? "activeStoreName mb-1" : "dropdown_sidebar_store mb-3"
              }`}
            >
              <span
                className={`${
                  skoyen ? "storeActiveText dropdown_text" : "dropdown_text"
                }`}
              >
                {Stores[3]?.store_name}
              </span>

              <span
                className={`${
                  skoyen
                    ? "dropdown_text_view storeActiveText"
                    : "dropdown_text_view"
                }`}
                onClick={() => {
                  setSkoyen(!skoyen);
                }}
              >
                {skoyen ? "Close" : " View"}
              </span>
            </div>
            {skoyen && (
              <div className="dropdown_branch">
                <div className="d-flex flex-column justify-content-start align-items-start">
                  <div className="d-flex flex-row justify-content-start align-items-center main-content-div">
                    <i className="bi bi-telephone-fill phone-text mr-4"></i>
                    <span className="phone-text">922 79 588</span>
                  </div>
                  <div className="d-flex flex-row justify-content-start align-items-center main-content-div">
                    <i className="bi bi-envelope-fill phone-text mr-4"></i>
                    <span className="phone-text">{Stores[3].store_email}</span>
                  </div>
                  <div className="d-flex flex-row justify-content-start align-items-center main-content-div">
                    <i className="bi bi-sign-intersection-side-fill phone-text mr-4"></i>
                    <span className="phone-text">
                      Karenlyst alle 30, 0278 Oslo
                    </span>
                  </div>

                  {/* {Stores[3].isDays === 1 && Stores[3].daysTiming ? (
                      <span className="phone-text">
                        Man - Fre {Stores[3].daysTiming[0].opening} -{" "}
                        {Stores[3].daysTiming[0].closing}
                      </span>
                    ) : (
                      <span className="phone-text">
                        Man - Fre {Stores[3].store_open_time} -{" "}
                        {Stores[3].store_close_time}
                      </span>
                    )} */}

                  <>
                    {Stores[3].isDays === 1 && Stores[3].daysTiming ? (
                      <>
                        {/* Group days with same timings together */}
                        {groupDaysBySameTimings(Stores[3].daysTiming).map(
                          (group, index) => (
                            <div key={index}>{displayDayTiming(group)}</div>
                          )
                        )}
                      </>
                    ) : (
                      // If isDays is 0 or not provided, display a message or handle as needed
                      <span className="phone-text">
                        No timing information available
                      </span>
                    )}
                  </>

                  {/* <div className="d-flex flex-row justify-content-start align-items-center main-content-div">
                    <i className="bi bi-clock phone-text mr-4"></i>
                    {Stores[3].isDays === 1 && Stores[3].daysTiming ? (
                      <span className="phone-text">
                        Lør - Søn {Stores[3].daysTiming[5].opening} -{" "}
                        {Stores[3].daysTiming[5].closing}
                      </span>
                    ) : (
                      <span className="phone-text">
                        Lør - Søn {Stores[3].store_open_time} -{" "}
                        {Stores[3].store_close_time}
                      </span>
                    )}
                  </div> */}
                  <div className="d-flex flex-row justify-content-start align-items-center main-content-div">
                    <i className="bi bi-x-square-fill phone-text mr-4"></i>
                    <span className="phone-text">
                      Kjøkkenet stenger {Stores[3].store_close_time}
                    </span>
                  </div>
                </div>
              </div>
            )}

            <div
              className={`d-flex justify-content-between align-items-center ${
                sagene
                  ? "activeStoreName  mb-1"
                  : "dropdown_sidebar_store  mb-3"
              }`}
            >
              <span
                className={`${
                  sagene ? "storeActiveText dropdown_text" : "dropdown_text"
                }`}
              >
                {Stores[1]?.store_name}
              </span>

              <span
                className={`${
                  sagene
                    ? "dropdown_text_view storeActiveText"
                    : "dropdown_text_view"
                }`}
                onClick={() => {
                  setSagene(!sagene);
                }}
              >
                {sagene ? "Close" : " View"}
              </span>
            </div>
            {sagene && (
              <div className="dropdown_branch">
                <div className="d-flex flex-column justify-content-start align-items-start">
                  <div className="d-flex flex-row justify-content-start align-items-center main-content-div">
                    <i className="bi bi-telephone-fill phone-text mr-4"></i>
                    <span className="phone-text">922 68 588</span>
                  </div>
                  <div className="d-flex flex-row justify-content-start align-items-center main-content-div">
                    <i className="bi bi-envelope-fill phone-text mr-4"></i>
                    <span className="phone-text">{Stores[1].store_email}</span>
                  </div>
                  <div className="d-flex flex-row justify-content-start align-items-center main-content-div">
                    <i className="bi bi-sign-intersection-side-fill phone-text mr-4"></i>
                    <span className="phone-text">
                      Stockfleths gt 53B, 0461 Oslo
                    </span>
                  </div>
                  {/* <div className="d-flex flex-row justify-content-start align-items-center main-content-div">
                    <i className="bi bi-clock phone-text mr-4"></i>
                    {Stores[1].isDays === 1 && Stores[1].daysTiming ? (
                      <span className="phone-text">
                        Man - Fre {Stores[1].daysTiming[0].opening} -{" "}
                        {Stores[1].daysTiming[0].closing}
                      </span>
                    ) : (
                      <span className="phone-text">
                        Man - Fre {Stores[1].store_open_time} -{" "}
                        {Stores[1].store_close_time}
                      </span>
                    )}
                  </div> */}
                  {/* <div className="d-flex flex-row justify-content-start align-items-center main-content-div">
                    <i className="bi bi-clock phone-text mr-4"></i>
                    {Stores[1].isDays === 1 && Stores[1].daysTiming ? (
                      <span className="phone-text">
                        Lør - Søn {Stores[1].daysTiming[5].opening} -{" "}
                        {Stores[1].daysTiming[5].closing}
                      </span>
                    ) : (
                      <span className="phone-text">
                        Lør - Søn {Stores[1].store_open_time} -{" "}
                        {Stores[3].store_close_time}
                      </span>
                    )}
                  </div> */}
                  <>
                    {Stores[1].isDays === 1 && Stores[1].daysTiming ? (
                      <>
                        {/* Group days with same timings together */}
                        {groupDaysBySameTimings(Stores[1].daysTiming).map(
                          (group, index) => (
                            <div key={index}>{displayDayTiming(group)}</div>
                          )
                        )}
                      </>
                    ) : (
                      // If isDays is 0 or not provided, display a message or handle as needed
                      <span className="phone-text">
                        No timing information available
                      </span>
                    )}
                  </>
                  <div className="d-flex flex-row justify-content-start align-items-center main-content-div">
                    <i className="bi bi-x-square-fill phone-text mr-4"></i>
                    <span className="phone-text">
                      Kjøkkenet stenger {Stores[1].store_close_time}
                    </span>
                  </div>
                </div>
              </div>
            )}

            <div
              id="uranianborg"
              className={`d-flex justify-content-between align-items-center  ${
                uranianborg ? "activeStoreName mb-1" : "dropdown_sidebar_store "
              }`}
            >
              <span
                className={`${
                  uranianborg
                    ? "storeActiveText dropdown_text"
                    : "dropdown_text"
                }`}
              >
                {Stores[0]?.store_name}
              </span>

              <span
                className={`${
                  uranianborg
                    ? "dropdown_text_view storeActiveText"
                    : "dropdown_text_view"
                }`}
                onClick={() => {
                  setUranianborg(!uranianborg);
                }}
              >
                {uranianborg ? "Close" : " View"}
              </span>
            </div>
            {uranianborg && (
              <div className="dropdown_branch">
                <div className="d-flex flex-column justify-content-start align-items-start">
                  <div className="d-flex flex-row justify-content-start align-items-center main-content-div">
                    <i className="bi bi-telephone-fill phone-text mr-4"></i>
                    <span className="phone-text">922 26 588</span>
                  </div>
                  <div className="d-flex flex-row justify-content-start align-items-center main-content-div">
                    <i className="bi bi-envelope-fill phone-text mr-4"></i>
                    <span className="phone-text">{Stores[0].store_email}</span>
                  </div>
                  <div className="d-flex flex-row justify-content-start align-items-center main-content-div">
                    <i className="bi bi-sign-intersection-side-fill phone-text mr-4"></i>
                    <span className="phone-text">
                      Uranienborgvn 7B, 0351 Oslo
                    </span>
                  </div>
                  {/* <div className="d-flex flex-row justify-content-start align-items-center main-content-div">
                    <i className="bi bi-clock phone-text mr-4"></i>
                    {Stores[0].isDays === 1 && Stores[0].daysTiming ? (
                      <span className="phone-text">
                        Man - Fre {Stores[0].daysTiming[0].opening} -{" "}
                        {Stores[0].daysTiming[0].closing}
                      </span>
                    ) : (
                      <span className="phone-text">
                        Man - Fre {Stores[0].store_open_time} -{" "}
                        {Stores[0].store_close_time}
                      </span>
                    )}
                  </div> */}
                  <>
                    {Stores[0].isDays === 1 && Stores[0].daysTiming ? (
                      <>
                        {/* Group days with same timings together */}
                        {groupDaysBySameTimings(Stores[0].daysTiming).map(
                          (group, index) => (
                            <div key={index}>{displayDayTiming(group)}</div>
                          )
                        )}
                      </>
                    ) : (
                      // If isDays is 0 or not provided, display a message or handle as needed
                      <div className="d-flex flex-row justify-content-start align-items-center main-content-div">
                        <span className="phone-text">
                          No timing information available
                        </span>
                      </div>
                    )}
                  </>
                  {/* <div className="d-flex flex-row justify-content-start align-items-center main-content-div">
                    <i className="bi bi-clock phone-text mr-4"></i>
                    {Stores[0].isDays === 1 && Stores[0].daysTiming ? (
                      <span className="phone-text">
                        Lør - Søn {Stores[0].daysTiming[5].opening} -{" "}
                        {Stores[0].daysTiming[5].closing}
                      </span>
                    ) : (
                      <span className="phone-text">
                        Lør - Søn {Stores[0].store_open_time} -{" "}
                        {Stores[0].store_close_time}
                      </span>
                    )}
                  </div> */}
                  <div className="d-flex flex-row justify-content-start align-items-center main-content-div">
                    <i className="bi bi-x-square-fill phone-text mr-4"></i>
                    <span className="phone-text">
                      Kjøkkenet stenger {Stores[0].store_close_time}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* </div> */}
        </Box>
      </Modal>
    </div>
  );
};

export default Sidebar;

import React, { useState, useEffect } from "react";
import { BsEyeSlash } from "react-icons/bs";
import PhoneInput from "react-phone-input-2";
import { connect } from "react-redux";
import {
  signupk,
  loginCustomerWithSocial,
  customerSignIn,
} from "../../Redux/Actions/index";
import "react-phone-input-2/lib/style.css";
import { Link } from "react-router-dom";
import "./NewLogin.css";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin } from "react-google-login";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";


var generator = require("generate-password");

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: [357, 560, 560],
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  borderRadius: "20px",
  p: 4,
};

export function LoginLeftPart() {
  return (
    <>
      <div className="">
        <img
          className="mx-lg-0 mx-md-auto mx-sm-auto mx-auto img-fluid d-md-block d-none"
          src={process.env.PUBLIC_URL + "/login.png"}
          alt="Palace Cafe Logo"
          style={{
            height: "366px",
            width: "490px",
            // width: windowWidth > 560 ? '490px' : '358px',
            borderRadius: "26px",
            objectFit: 'contain'
          }}
        />
      </div>
    </>
  );
}

export function LoginRightPart(props: any) {
  const { t } = useTranslation();
  const [phone, setPhone] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update windowWidth whenever the window is resized
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleOnChange = (value: any) => {
    setPhone(value);
  };

  const handleInputChange = (e: any) => {
    setPassword(e.target.value);
  };

  const validateemail = (email: any) => {
    let reg =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    console.log(reg.test(String(email).toLowerCase()), "regex etst for mobile");
    return reg.test(String(email).toLowerCase());
  };

  const handleSubmit = () => {
    localStorage.removeItem("orderId");
    sessionStorage.removeItem("guestphone");
    let { history } = props;
    if (validateemail(email)) {
      let data = {
        email: email,
        password: password,
      };
      props.customerSignIn(data, history);
    } else {
      let str_phone_number = phone;
      if (str_phone_number.charAt(0) === "9") {
        str_phone_number = str_phone_number.substring(2);
        str_phone_number = "0" + str_phone_number;
      }
      let data = {
        phone_number: str_phone_number,
        password: password,
      };
      props.customerSignIn(data, history);
    }
  };

  const responseFacebook = (response: any) => {
    const data: any = {
      first_name: response.first_name,
      last_name: response.last_name,
      login_name: response.name,
      email_address: response.email,
      profile_pic: response.picture.data.url,
      is_active: 1,
      is_social_login: 1,
    };
    console.log("facebook login response", data);
    props.loginCustomerWithSocial(data, props.history);
  };

  const responseGoogle = (response: any) => {
    // console.log(response);
    var password = generator.generate({
      length: 10,
      numbers: true,
    });
    if (response) {
      const data: any = {
        login_name: response.profileObj.name,
        email_address: response.profileObj.email,
        profile_pic: response.profileObj.imageUrl,
        phone_number: password,
        is_active: 1,
        is_social_login: 1,
      };
      console.log("google login response", data);
      props.loginCustomerWithSocial(data, props.history);
    }
    let token = sessionStorage.getItem("token");
  };
  const validateEmail = (event: any) => {
    let reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (event.target.value !== "") {
      if (reg.test(event.target.value)) {
        setEmail(event.target.value);
        setIsValidEmail(true);
      } else {
        setEmail(event.target.value);
        setIsValidEmail(false);
      }
    } else {
      setEmail(event.target.value);
      setIsValidEmail(true);
    }
  };

  const checkPhone = (phone: any) => {
    if (phone) {
      // var phoneno = /^\+?([0-9]{3})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{5})$/;
      let phoneno = /^(?:\92|3)(?:3)[0-9]{9}$/;
      // var mobileno = /^((\\+92-?)|0)?[0-9]{11}$/;
      if (phone.match(phoneno)) {
        return true;
      } else {
        return false;
      }
    }
  };
  const isUserReady = () => {
    let validateSignal = false;
    if (password !== "") {
      validateSignal = true;
    }
    if (phone.length != 9) {
      validateSignal = false;
    }
    if (!checkPhone(phone)) {
      validateSignal = false;
    }
    return validateSignal;
  };
  console.log("height", window.screen.height);
  console.log("width", window.screen.width);

  return (
    <>
      <div className="">
        <div className="about-details">
          <p className="login-heading m-0">Log In</p>
          <div className="heading">
            <p
              style={{
                marginTop: "23px",
                marginBottom: "5px",
                color: "#212524",
                fontFamily: "Quicksand",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "20px",
              }}
              className="font_custom"
            >
              Email address
            </p>
            <input
              id="round"
              type="email"
              name="email"
              className="form-control input-font-size mb-0"
              placeholder="example@email.com"
              required
              onChange={(e) => validateEmail(e)}
              style={{
                borderRadius: "8px",
                border: "1px solid var(--grey-grey-100, #BABBBB)",
                background: "#FFF",
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                paddingLeft: "7px",
                fontFamily: "Quicksand",
                fontStyle: "normal",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "24px",
              }}
            />
            {!isValidEmail && email !== "" && (
              <small className="text-danger">Invalid email</small>
            )}
            <p
              style={{
                marginTop: "28px",
                marginBottom: "4px",
                fontFamily: "Quicksand",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "20px",
                fontSize: "14px",
              }}
              className="font_custom"
            >
              Password
            </p>
            <div>
              <input
                id="round"
                type="password"
                name="password"
                className="form-control input-font-size"
                placeholder="Password"
                required
                onChange={(e) => handleInputChange(e)}
                style={{
                  borderRadius: "8px",
                  border: "1px solid var(--grey-grey-100, #BABBBB)",
                  background: "#FFF",
                  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                  paddingLeft: "7px",
                  fontFamily: "Quicksand",
                  fontStyle: "normal",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "24px",
                  marginBottom: "24px",
                }}
              />
            </div>
            <Link to="/reset-password" className="forget-pass">
              Forgot password
            </Link>
            <button
              type="button"
              className="btn login-btn custom_color"
              onClick={handleSubmit}
              style={{
                marginTop: "36px",
                display: "flex",
                height: "50px",
                padding: "12px 18px",
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
                flex: "1 0 0",
                borderRadius: "10px",
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
              }}
            >
              <span className="shopping-name">Log In</span>
            </button>
            <div className="container">
              <div className="row justify-content-center align-item-center mt-lg-2">
                <div className="col-12 col-md-12 col-lg-3 d-flex justify-content-center my-3 my-md-0 mt-md-2 mt-lg-0 my-lg-0 order-md-2">
                  <a href="/signup" className="link-position">
                    <button type="button" className="btn lgn-signup-btn-1">
                      Create an account
                    </button>
                  </a>
                </div>
                <div className="col-12 col-md-12 col-lg-5 d-flex justify-content-center order-md-1">
                  <p className="text-center ">Don't have an account?</p>
                </div>
              </div>
            </div>
            <div className="col-3"></div>
          </div>
        </div>
        {props.isLoggedInError === false ? (
          <div className="alert alert-success alert-dismissible">
            <button type="button" className="close" data-dismiss="alert">
              &times;
            </button>
            <strong>Thank You</strong> Success Login
          </div>
        ) : (
          <div></div>
        )}
        {props.isLoggedInError === true ? (
          <div className="alert alert-danger alert-dismissible">
            <button type="button" className="close" data-dismiss="alert">
              &times;
            </button>
            <strong>{props.loginErrMessage}</strong>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </>
  );
}

function NewLogin(props: any) {
  const { t } = useTranslation();
  const [phone, setPhone] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    // Update windowWidth whenever the window is resized
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const param1 = urlParams.get("param");
    if (param1) {
      setOpen(true);
    }
  },[])

  console.log("height", window.screen.height);
  console.log("width", window.screen.width);
  return (
    <main
      className="main"
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <div className="container p-0 login_margin_custom" style={{ marginTop: "130px" }}>
        <div className="row p-0" style={{ margin: "0 31px" }}>
          <div className="col-lg-auto col-md-12 col-sm-12 col-12 mt-10 mx-auto p-0">
            <LoginLeftPart />
          </div>
          <div
            className="col-lg-6 col-md-9 col-sm-9 col-12 mt-10 p-0 mx-lg-auto mx-md-auto mx-sm-auto mx-2"
            style={{
              marginLeft: windowWidth > 991 ? "55px" : "20px",
              marginBottom: "auto",
            }}
          >
            <LoginRightPart customerSignIn={props.customerSignIn} />
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="d-flex flex-column ">
            <div className="d-flex mb-2">
              <span className="modal-heading">Download Application</span>
              <div className="close-btn-order" onClick={handleClose}>
                <i className="bi bi-x"></i>
              </div>
            </div>
            <hr className="my-2" />
            <div className="d-md-flex flex-md-row flex-column justify-content-md-around sec-button-modal mt-2 d-none">
              <img
                className="qr-code-image"
                src={process.env.PUBLIC_URL + "/apple.png"}
                alt="qr code"
              />
              <img
                className="qr-code-image"
                src={process.env.PUBLIC_URL + "/play.png"}
                alt="qr code"
              />
            </div>
            <div className="d-flex flex-md-row flex-column justify-content-md-around sec-button-modal mt-2 d-md-none">
              <a href="">
                <img
                  className="qr-code-image"
                  src={process.env.PUBLIC_URL + "/apple.png"}
                  alt="qr code"
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=no.palacecafeapp.etcnor&hl=en&gl=US">
                <img
                  className="qr-code-image"
                  src={process.env.PUBLIC_URL + "/play.png"}
                  alt="qr code"
                />
              </a>
            </div>
          </div>
        </Box>
      </Modal>
    </main>
  );
}
const mapDispatchToProps = (dispatch: any) => {
  return {
    customerSignIn: function (data: any, history: any) {
      dispatch(customerSignIn(data, history));
    },
    loginCustomerWithSocial: function (data: any, history: any) {
      dispatch(loginCustomerWithSocial(data, history));
    },
  };
};
const mapStateToProps = (state: any) => {
  return {
    isLoggedInError: state.login.isLoggedInError,
    loginErrMessage: state.login.loginErrorMessage,
    message: state.login.message,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewLogin);

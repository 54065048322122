import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";

class Privacy extends Component {
  constructor(props: any) {
    super(props);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <main className="main-privacy" style={{ backgroundColor: "#f7f7f7" }}>
        <div className="about-section">
          <div className="container">
            <h2 className="subtitle">Privacy &nbsp; Policy</h2>
            <div className="row p-3">
              <div
                className="col-lg-12 col-12 p-5"
                style={{
                  margin: "auto",
                  backgroundColor: "white",
                  borderRadius: "24px",
                  boxShadow: "0px 16px 24px rgba(0, 0, 0, 0.08)",
                }}
              >
                <div className="about-details">
                  <div className="col-lg-12">
                    <p className="text-center">
                      <strong>Personvernerklæring</strong>
                    </p>
                    <p>
                      <strong>1. Innledning</strong>
                    </p>
                    <p>
                      Bamboo Skøyen AS, Bamboo Uranienborg AS, Bamboo Bjerke AS
                      respekterer personvernet til våre brukere. Denne
                      personvernerklæringen forklarer hvordan vi samler inn,
                      bruker, deler, og beskytter informasjonen din når du
                      besøker nettstedet vårt: www.bamboorestaurant.no.
                      Vennligst les denne personvernerklæringen nøye.
                    </p>

                    <p>
                      <strong>2. Data Vi Samler Inn</strong>
                    </p>
                    <p>Vi kan samle inn følgende personopplysninger:</p>
                    <p>
                      <ul style={{ listStyle: "disc" }} className="ml-5">
                        <li>Navn</li>
                        <li>
                          Kontaktinformasjon (slik som
                          e-postadresse,telefonnummer)
                        </li>
                        <li>IP-adresse</li>
                        <li>Nettlesertype og versjon</li>
                        <li>Ordre og bestillingsinformasjon</li>
                        <li>
                          Betalingsopplysninger med unntak av kortinformasjon
                        </li>
                      </ul>
                      Denne informasjonen samles inn ved besøk. Vi bruker Google
                      Analytics for å samle statistikk om bruken av nettstedet.
                      På denne måten får vi innsikt i hvordan vi kan gjøre
                      tjenesten enda bedre. Vi får informasjon om hvilke sider
                      og tjenester som benyttes, hvor brukeren kommer fra, tid
                      brukt på sidene osv. Google Analytics sletter
                      personidentifiserende opplysninger ved mottak, og
                      IP-adressen din blir anonymisert.
                    </p>
                    <p>
                      Nets er databehandler for informasjon tilknyttet selve
                      betalingen og kan kontaktes for eventuelle spørsmål.
                    </p>
                    <p>
                      <strong>3. Hvordan Vi Bruker Dine Data</strong>
                    </p>
                    <p>
                      Vi bruker dine personopplysninger til følgende formål:
                    </p>
                    <p>
                      <ul style={{ listStyle: "disc" }} className="ml-5">
                        <li>For å levere og vedlikeholde tjenestene våre</li>
                        <li>For å varsle deg om endringer i våre tjenester</li>
                        <li>For å tilby kundestøtte</li>
                        <li>For å overvåke bruken av våre tjenester</li>
                        <li>
                          Lagring av informasjon som vi er pålagt etter
                          regnskapsloven
                        </li>
                      </ul>
                    </p>
                    <p>
                      <strong>4. Deling av Dine Data</strong>
                    </p>
                    <p>
                      Vi selger, handler eller leier ikke ut dine
                      personopplysninger til andre med mindre loven krever
                      dette. Vi kan dele dataene dine med tredjeparts
                      tjenesteleverandører for å levere nødvendig
                      nettstedfunksjonalitet og forbedre kvaliteten på
                      tjenestene våre.
                    </p>
                    <p>
                      <strong>5. Dine GDPR Rettigheter</strong>
                    </p>
                    <p>Du har rett til:</p>
                    <p>
                      <ul style={{ listStyle: "disc" }} className="ml-5">
                        <li>Å be om tilgang til dine personopplysninger</li>
                        <li>
                          Å be om korreksjon av personopplysningene vi har om
                          deg
                        </li>
                        <li>Å be om sletting av dine personopplysninger</li>
                        <li>
                          Å protestere mot behandling av dine personopplysninger
                        </li>
                        <li>
                          Å be om begrensning av behandling av dine
                          personopplysninger
                        </li>
                        <li>
                          Å be om overføring av dine personopplysninger til deg
                          eller en tredjepart
                        </li>
                        <li>
                          Å trekke tilbake samtykke når som helst der vi er
                          avhengige av samtykke for å behandle dine
                          personopplysninger
                        </li>
                      </ul>
                    </p>
                    <p>
                      <strong>6. Datasikkerhet</strong>
                    </p>
                    <p>
                      Vi er forpliktet til å sikre at informasjonen din er
                      trygg. Vi har iverksatt passende sikkerhetstiltak for å
                      forhindre at dine personopplysninger går tapt, brukes
                      eller får uautorisert tilgang. Data lagres på våre servere
                      som befinner seg innenfor EU og er forpliktet til å følge
                      EU GDPR regelverk.
                    </p>
                    <p>
                      <strong>7. Lagring av Data</strong>
                    </p>
                    <p>
                      Vi vil kun beholde dine personopplysninger så lenge det er
                      nødvendig for å oppfylle formålene vi samlet dem inn for.
                    </p>
                    <p>
                      <strong>8. Lenker til Tredjepart</strong>
                    </p>
                    <p>
                      Tjenestene våre kan inneholde lenker til andre nettsteder.
                      Hvis du klikker på en tredjepartslenke, vil du bli sendt
                      til det nettstedet. Merk at disse eksterne nettstedene
                      ikke drives av oss. Derfor oppfordrer vi deg til å lese
                      personvernerklæringen på disse nettstedene.
                    </p>
                    <p>
                      <strong>9. Barns Personvern</strong>
                    </p>
                    <p>
                      Våre tjenester er ikke ment for personer under 13 år. Vi
                      samler ikke bevisst inn personidentifiserbar informasjon
                      fra barn under 13 år.
                    </p>
                    <p>
                      <strong>
                        10. Endringer i Denne Personvernerklæringen
                      </strong>
                    </p>
                    <p>
                      Vi kan oppdatere vår personvernerklæring fra tid til
                      annen. Vi vil varsle deg om eventuelle endringer ved å
                      publisere den nye personvernerklæringen på denne siden.
                    </p>
                    <p>
                      <strong>11. Kontakt Oss</strong>
                    </p>
                    <p>
                      For spørsmål om denne personvernerklæringen eller
                      behandlingen av dine personopplysninger, vennligst skriv
                      til: post@bamboorestaurant.no
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Privacy;
